import styled from "styled-components";

export const DarkButton = styled.button`
    padding: 5px 10px;
    font-size: 12px;
    display: inline-block;
    color: #fff;
    background-color: #444;
    border-radius: 3px;
    line-height: 1.3;
    cursor: pointer;
    width: fit-content;
    height: fit-content;

    svg {
        margin-right: 5px;
        vertical-align: text-top;
    }
`
