import React, {useRef} from 'react'
import {callModal} from "../../dynamic-modal";
import Modal from "react-modal";
import {Field, Formik} from "formik";
import {ControlLabel, ModalTitle} from "react-bootstrap";
import {ModalButton, ModalContent, ModalControls, ModalHR} from "../../common/ModalParts";
import TextField from "../../common/TextArea";
import {useCloseOnClickOutside} from "../../dynamic-modal/helpers/use-close-on-click-outside";
import {getStyle} from "../../dynamic-modal/helpers/get-style";
import {archive, relevant} from "../reducer/selectors";

const Actions = {
    Enter: Symbol('enter'),
    Close: Symbol('close'),
}

export const AddStickerModal = ({isOpen, resolve, ownProps}) => {

    const modalRef = useRef()

    const {item} = ownProps

    const refSetter = ref => value => {
        ref.current = value
    }

    useCloseOnClickOutside({
        ref: modalRef,
        onClose: () => resolve(Actions.Close),
    })

    return (
        <Modal
            isOpen={isOpen}
            contentRef={refSetter(modalRef)}
            style={getStyle({width: 600, zIndex: 1000})}
        >
            <Formik
                initialValues={item
                    ? item
                    : {
                        content: "",
                        category: relevant
                    }}
                onSubmit={(values, actions) => {
                    resolve(Actions.Enter, values)
                    actions.setSubmitting(false)
                }}
            >
                {({values, setFieldValue, handleSubmit}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <ModalTitle style={{padding: 16}}>Напоминание</ModalTitle>
                            <ModalHR/>
                            <ModalContent>
                                <div role="group" style={{display: 'grid', marginBottom: '15px'}}>
                                    <ControlLabel>Статус</ControlLabel>
                                    <label>
                                        <Field type="radio" name="category" value={relevant}
                                               checked={values.category === relevant}
                                               style={{marginRight: '5px'}}/>
                                        В работе
                                    </label>
                                    <label>
                                        <Field type="radio" name="category" value={archive}
                                               checked={values.category === archive}
                                               style={{marginRight: '5px'}}/>
                                        Архивная
                                    </label>
                                </div>
                                <TextField
                                    name="Текст напоминания"
                                    value={values.content}
                                    onChange={(val) => setFieldValue("content", val)}/>
                            </ModalContent>
                            <ModalHR/>
                            <ModalControls>
                                <ModalButton
                                    bsStyle="danger"
                                    onClick={() => resolve(Actions.Close)}
                                >
                                    Отмена
                                </ModalButton>
                                <ModalButton
                                    type="submit"
                                    bsStyle="success"
                                    style={{marginLeft: 'auto'}}
                                >
                                    Создать
                                </ModalButton>
                            </ModalControls>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export async function createStickerModal(item) {
    const {action, params} = await callModal(AddStickerModal, {item})

    if (action === Actions.Close) return null

    return params
}

