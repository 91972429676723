import styled from 'styled-components'

export const Segments = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Segment = styled.div`
    height: 100%;
    width: 50%;

    &:first-child {
        padding-right: 5px;
    }

    &:last-child {
        padding-left: 5px;
    }

    input[type=text] {
        width: 100px;
    }
`

export const Content = styled.div`
    font-size: 12px;
`

export const SecondaryTitle = styled.h3`
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 12px;
`

export const Actions = styled.div`
    margin-top: 12px;
`

export const RoomInfo = styled.p`
    margin-top: 6px;
    margin-bottom: 6px;
`

export const RoomsNumber = styled.span`
    font-weight: 700;
`
