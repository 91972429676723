import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {currentDateSelectors} from '../../../../../Modules/current-date'
import {settingsSelectors} from '../../../../../Modules/settings'
import {gridSelectors} from '../../../reducers/grid'
import {Row, Table} from './Table'
import {todayCheckInsSelectors} from '../../../reducers/today-check-ins'
import {breakfastsBookedSelectors} from "../../../../../Modules/breakfastsBooked";
import {sessionSelectors} from '../../../../../Modules/session'
import {Template} from "../common/Template";

export const BreakfastsToday = () => {
    const {breakfasts, loading, loaded, failed} = useBreakfastsToday()

    if (loading) return null
    if (!loaded) return null
    if (failed) return null
    if (breakfasts.length === 0) return null

    return (
        <Template header='Брони с завтраками сегодня'>
            <Content breakfasts={breakfasts}/>
        </Template>
    )
}

const Content = ({breakfasts}) => {
    const reservationsByPk = useSelector(gridSelectors.reservationsByPk)
    const reservationsByBookingNumber = useSelector(
        gridSelectors.reservationsByBookingNumber
    )
    const checkinsReservationsByBookingNumber = useSelector(
        todayCheckInsSelectors.reservationsByBookingNumber
    )

    return (
        <Table>
            {breakfasts.map((breakfast, index) => {
                const pk = breakfast.booking_number_uuid
                const booking_number = breakfast.booking_number

                return (
                    <Row
                        key={`${pk}-${breakfast.service_uuid}-${index}`}
                        reservation={
                            reservationsByPk[pk] ||
                            reservationsByBookingNumber[booking_number] ||
                            checkinsReservationsByBookingNumber[booking_number]
                        }
                        breakfast={breakfast}
                    />
                )
            })}
        </Table>
    )
}

function useBreakfastsToday() {
    const today = useSelector(currentDateSelectors.today)
    const lcode = useSelector(sessionSelectors.lcode)
    const reservationsByPk = useSelector(gridSelectors.reservationsByPk)
    const reservationsByBookingNumber = useSelector(
        gridSelectors.reservationsByBookingNumber
    )
    const checkinsReservationsByBookingNumber = useSelector(
        todayCheckInsSelectors.reservationsByBookingNumber
    )
    const isGridLoading = useSelector(gridSelectors.isLoading)
    const isGridLoaded = useSelector(gridSelectors.isLoaded)
    const isGridFailed = useSelector(gridSelectors.isFailed)
    const isSettingsLoading = useSelector(settingsSelectors.isLoading)
    const isSettingsLoaded = useSelector(settingsSelectors.isLoaded)
    const isCheckinsLoading = useSelector(todayCheckInsSelectors.isLoading)
    const isCheckinsLoaded = useSelector(todayCheckInsSelectors.isLoaded)
    const isCheckinsFailed = useSelector(todayCheckInsSelectors.isFailed)
    const breakfastActions = useSelector(breakfastsBookedSelectors.breakfastsIsNotGivenAway)
    const isBreakfastsLoading = useSelector(breakfastsBookedSelectors.isLoading)
    const isBreakfastsLoaded = useSelector(breakfastsBookedSelectors.isLoaded)
    const isBreakfastsFailed = useSelector(breakfastsBookedSelectors.isFailed)

    const loading =
        isGridLoading ||
        isBreakfastsLoading ||
        isSettingsLoading ||
        isCheckinsLoading
    const loaded =
        isGridLoaded && isBreakfastsLoaded && isSettingsLoaded && isCheckinsLoaded
    const failed = isGridFailed || isBreakfastsFailed || isCheckinsFailed

    const todayBreakfastActions = useMemo(() => {
        if (loading) return null
        if (!loaded) return null
        if (failed) return null

        return breakfastActions
            .filter(breakfast => breakfast.lcode === lcode)
            .filter(breakfast => breakfast.delivery_date === today)
            .filter(
                breakfast =>
                    reservationsByPk[breakfast.booking_number_uuid] ||
                    reservationsByBookingNumber[breakfast.booking_number] ||
                    checkinsReservationsByBookingNumber[breakfast.booking_number]
            )
    }, [
        loading,
        loaded,
        failed,
        today,
        lcode,
        breakfastActions,
        reservationsByPk,
        reservationsByBookingNumber,
        checkinsReservationsByBookingNumber,
    ])

    return {
        breakfasts: todayBreakfastActions,
        loading,
        loaded,
        failed,
    }
}
