import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import history from './Modules/helpers/history'
import store from './store'
import './index.css'
import './App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from './App'
import SentryWrapper from './SentryWrapper'
import { Scheduler } from './Modules/scheduler'
import { setupAutoLogOut } from './Modules/session/actions'
import { setupInteceptors } from './Modules/api/setup-interceptors'
import { startBlockingAnyAppActionsWhileOnReservationPage } from './Modules/critical-operations'

registerLocale('ru', ru)

Sentry.init({
  dsn: 'https://237d66748315489d92ee206a84646245@sentry.io/1310630',
})

store.dispatch(setupAutoLogOut())

Scheduler.start()

setupInteceptors()

startBlockingAnyAppActionsWhileOnReservationPage()

ReactDOM.render(
  <SentryWrapper>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </SentryWrapper>,
  document.getElementById('root')
)
