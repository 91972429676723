import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useActions } from '../../../../common/hooks/use-actions'
import {
  reservationsWaitingForBonusesActions,
  reservationsWaitingForBonusesSelectors,
} from '../../../reducers/reservations-waiting-for-bonuses'
import { useSelector } from 'react-redux'
import { gridSelectors } from '../../../reducers/grid'
import moment from 'moment'
import { Table } from './components/Table'
import { Row } from './components/Row'
import {Template} from "../common/Template";

const Content = () => {
  const gridLoadState = useSelector(gridSelectors.loadState)
  const clientPhonesLoadState = useSelector(
    reservationsWaitingForBonusesSelectors.loadState
  )
  const matchingReservations = useSelector(
    reservationsWaitingForBonusesSelectors.matchingReservations
  )

  const sortedReservations = useMemo(() => {
    return [...matchingReservations].sort((reservationA, reservationB) => {
      return moment(reservationA.start).diff(reservationB.start, 'days')
    })
  }, [matchingReservations])

  if (gridLoadState.isLoading) {
    return <div>Загрузка броней..</div>
  }

  if (clientPhonesLoadState.isLoading) {
    return <div>Поиск броней с зарегистрированными клиентами..</div>
  }

  if (matchingReservations.length === 0) {
    return <div>Ничего не найдено</div>
  }

  return (
    <Table>
      {sortedReservations.map(reservation => {
        return <Row key={reservation.pk} reservation={reservation} />
      })}
    </Table>
  )
}

function useShowReservationsWaitingForBonuses() {
  const isGridLoading = useSelector(gridSelectors.isLoading)
  const isLoyaltyClientsLoading = useSelector(
    reservationsWaitingForBonusesSelectors.isLoading
  )
  const matchingReservations = useSelector(
    reservationsWaitingForBonusesSelectors.matchingReservations
  )
  return !(!isGridLoading &&
    !isLoyaltyClientsLoading &&
    matchingReservations.length === 0);
}

export const ReservationsWaitingForBonuses = () => {
  const gridLoadState = useSelector(gridSelectors.loadState)

  const { loadLoyaltyClients, reset } = useActions(
    reservationsWaitingForBonusesActions
  )

  useEffect(() => {
    if (gridLoadState.isLoaded) {
      loadLoyaltyClients()
    }
  }, [gridLoadState, loadLoyaltyClients])

  useEffect(() => {
    return () => reset()
  }, [reset])

  const show = useShowReservationsWaitingForBonuses()

  if (!show) {
    return null
  }

  return (
    <Template header='Брони с неначисленными бонусами'>
      <Content />
    </Template>
  )
}
