import React, {useEffect} from 'react'
import {useActions} from '../../../../common/hooks/use-actions'
import {maidsActions, maidsSelectors} from '../../../../../Modules/maids'
import {useSelector} from 'react-redux'
import {sessionSelectors} from '../../../../../Modules/session'
import {MaidsCheckOut} from '../../../../BedclothesChange/components/MaidsCheckOut'
import {Template} from "../common/Template";


export const MaidsCheckOutMain = () => {
    const lcode = useSelector(sessionSelectors.lcode)
    const checkedList = useSelector(maidsSelectors.checkedInList)
    const {getMaidsCheckedIns} = useActions(maidsActions)

    useEffect(() => {
        getMaidsCheckedIns({lcode})
    }, [])

    return (
        checkedList.length ?
            <Template header='Горничные на смене:'>
                <MaidsCheckOut/>
            </Template> : ''
    )

}
