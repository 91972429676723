import {ModalButton, ModalControls, ModalHR, ModalTitle} from '../../../../common/ModalParts'
import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import {ModalBody} from 'react-bootstrap'
import {Select} from '../../../../form-stuff'
import {ccAPI} from '../../../../../Modules/api/ccAPI'
import {useSelector} from 'react-redux'
import {sessionSelectors} from '../../../../../Modules/session'
import PureDatePicker from 'react-datepicker'
import {room_statuses, room_statuses_values} from './room-statuses'
import moment from 'moment/moment'
import generateGUID from '../../../../../Modules/CheckPrintController/generateGUID'
import styled from 'styled-components'
import {FaPlus, FaTimes} from 'react-icons/fa'
import {Button} from '../../../../buttons'
import {callAlert, callPrompt} from '../../../../dynamic-modal'
import {useActions} from '../../../../common/hooks/use-actions'
import {roomsActions} from '../../../../../Modules/rooms'
import uuid from 'uuid/v4'
import {History} from "./History";
import CheckBoxComponent from "../../../../common/Checkbox";
import {settingsSelectors} from "../../../../../Modules/settings";
import {Notifications} from "../../../../../Modules/Notifications";

const Flex = styled.div`
    display: flex;
    margin-bottom: 10px;
    gap: 10px;


    > div:first-child {
        width: 50%;

        b {
            display: block;
        }
    }

    button {
        margin: 0;

        svg {
            height: 100%;
        }
    }
`

const be_format = 'YYYY-MM-DD'
const fe_format = 'DD.MM.YYYY'

const defaultData = {repair: {}, busy_with_employee: {}, all: {}}

export const ChangeRoomStatusModal = ({isOpen, close, room}) => {
    const {room_id, status: currentStatus, repair_schedule, busy_with_employee_dates, cleaning_second_tier} = room

    const isCleaningSecondTierEnabled = useSelector(settingsSelectors.isCleaningSecondTierEnabled)
    const lcode = useSelector(sessionSelectors.lcode)
    const {getChangeRoomStatus: updateRoomStatus, clearChangesHistory} = useActions(roomsActions)
    const [status, setStatus] = useState(currentStatus)
    const [statusDateData, setStatusDateData] = useState(defaultData)
    const [cleaningSecondTier, setCleaningSecondTier] = useState(cleaning_second_tier || false)
    const [isDirty, setIsDirty] = useState(status === room_statuses.dirty.title)
    const [error, setError] = useState(true)

    const schedule = repair_schedule.length ? repair_schedule : busy_with_employee_dates
    const hasScheduleStatuses = [room_statuses.repair.title, room_statuses.busy_with_employee.title]

    const onClose = () => {
        setCleaningSecondTier(false)
        clearChangesHistory()
        setStatusDateData(defaultData)
        setIsDirty(false)
        setError(null)
        close()
    }

    useEffect(() => {

        const getData = (arr) => arr.reduce((a, b) => {
            a[generateGUID()] = b
            return a
        }, arr.length ? {} : {
            [generateGUID()]: {
                start_date: null,
                end_date: null
            }
        })

        const data = getData(schedule)

        setStatusDateData({
            repair: getData(repair_schedule),
            busy_with_employee: getData(busy_with_employee_dates),
            all: data
        })
    }, [schedule])

    useEffect(() => {
        setError(null)
        setIsDirty(status === room_statuses.dirty.title)
    }, [status]);

    useEffect(() => {
        setStatus(currentStatus)
    }, [currentStatus]);


    const add = (status) => {
        const data = statusDateData[status]

        setStatusDateData({
            ...statusDateData,
            [status]: {
                ...data,
                [generateGUID()]: {
                    start_date: null,
                    end_date: null
                }
            }
        })

    }
    const setSelectedDates = (uid, status, {start_date, end_date}) => {

        const data = statusDateData[status]
        setStatusDateData({
            ...statusDateData,
            [status]: {
                ...data, [uid]: {start_date, end_date}
            }
        })
    }

    const remove = (uid, status) => {
        const copy = statusDateData[status]
        delete copy[uid]

        setStatusDateData({
            ...statusDateData,
            [status]: {
                ...copy,
            }
        })
    }

    const save = async () => {
        const data = {lcode, room_id, status, send_notification: true, cleaning_second_tier: cleaningSecondTier}
        const getPassword = hasScheduleStatuses.includes(status)

        if (hasScheduleStatuses.includes(status)) {
            data.dates = Object.values(statusDateData[status])
        }

        const send = async () => {
            setError(null)
            await updateRoomStatus(data).then(res => {
                if (res) {
                    Notifications.success('Успех')
                } else {
                    setError(error)
                    Notifications.failure('Что-то пошло не так')
                }
                onClose()
            })
        }

        if (getPassword) {
            await callPrompt({
                title: 'Проверка',
                text: [
                    'Введите пароль для смены дат.',
                    'Запросите его у тех.директора.',
                ],
            }).then(async password => {
                if (password == null) {
                    return
                }
                await ccAPI
                    .getRepairDatePassword(password)
                    .then(() => {
                        send()
                    })
                    .catch(() => {
                        callAlert({
                            title: 'Ошибка',
                            text: 'Неправильный пароль',
                        })
                    })
            })
        } else await send()

    }

    return (
        <Modal isOpen={isOpen}>
            <ModalTitle>Управление номером</ModalTitle>
            <ModalHR/>
            <ModalBody style={{height: '80vh', overflow: 'auto'}}>
                <div>
                    <Select
                        label="Статус комнаты"
                        options={room_statuses_values}
                        value={status}
                        onChange={setStatus}
                    />

                    {isDirty && isCleaningSecondTierEnabled && <CheckBoxComponent
                        value={cleaningSecondTier}
                        title="Застелить второй ярус"
                        onChange={setCleaningSecondTier}
                    />}

                    {error && <div style={{color: "#d61b3e"}}>{error}</div>}
                    {schedule.length && !hasScheduleStatuses.includes(status) ? <>
                        {!!repair_schedule.length && <div style={{marginBottom: "10px"}}>
                            В данном номере запланирован ремонт:
                            <div>
                                {repair_schedule.map(({start_date, end_date}) =>
                                    <div key={uuid()}>
                                        <b>{moment(start_date).format(fe_format)} - {moment(end_date).format(fe_format)}</b>
                                    </div>
                                )}
                            </div></div>}
                        {!!busy_with_employee_dates.length && <div style={{marginBottom: "10px"}}>
                            Данный номер занят сотрудником:
                            <div>
                                {busy_with_employee_dates.map(({start_date, end_date}) =>
                                    <div key={uuid()}>
                                        <b>{moment(start_date).format(fe_format)} - {moment(end_date).format(fe_format)}</b>
                                    </div>
                                )}
                            </div></div>}
                        Для редактирования расписания ремонта выберите статус "Ремонт" или "Занят сотрудником"
                    </> : <></>}
                    {statusDateData[status] && hasScheduleStatuses.includes(status) &&
                        <>
                            Укажите даты начала и окончания.
                            {Object.entries(statusDateData[status])?.map(([uid, {start_date, end_date}], index) => {
                                    return <DatePicker key={uid}
                                                       start={start_date}
                                                       end={end_date}
                                                       setDates={data => setSelectedDates(uid, status, data)}
                                                       remove={() => remove(uid, status)}
                                                       add={() => add(status)}
                                                       first={index === 0}
                                    />
                                }
                            )}
                        </>
                    }
                    <History room_id={room_id} lcode={lcode}/>
                </div>
            </ModalBody>
            <ModalControls>
                <ModalButton
                    bsStyle="success"
                    onClick={save}
                >
                    Сохранить
                </ModalButton>
                <ModalButton
                    bsStyle="danger"
                    onClick={onClose}
                >
                    Отмена
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const DatePicker = ({start, end, setDates, remove, add, first}) => {
    const [startDate, setStartDate] = useState(start ? new Date(start) : null);
    const [endDate, setEndDate] = useState(end ? new Date(end) : null);
    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start);
        setEndDate(end);
        if (start && end)
            setDates({
                start_date: moment(start).format(be_format),
                end_date: moment(end).format(be_format)
            })
    }
    return (
        <Flex>
            <PureDatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd.MM.yyyy"
                selectsRange
            />
            {start && end && <Button
                size="small"
                type="danger"
                onClick={remove}
            >
                <FaTimes/>
            </Button>}
            {first && <Button
                size="small"
                onClick={add}
            >
                <FaPlus/>
            </Button>
            }
        </Flex>
    )
}
