import React from 'react'
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import {callModal, modalTemplates} from '../../../../dynamic-modal'
import {AboutCancel, InlineItem, QuestionIcon} from "./QuestionIcon";

export const CancelInfoModal = () => (
    <div>
        <strong>Если вы знаете, что по какой-то активной брони не будет заезда, вы можете использовать кнопку «Отмена».
            При этом забронированный номер снова уйдет в продажу, а бронь исчезнет из списка заездов.</strong>
        <p/>
        <p>Важно: Данное действие не отменяет бронь на сайте, котором бронировал гость. Вам все еще нужно будет подать
            по
            этой брони незаезд <a target="_blank"
                                  href="https://wf-manuals.teamly.ru/auth/sign-in?redirect=%2Fspace%2Fd42eada1-6310-4324-bca8-88546d99a3bb%2Farticle%2F7734da22-ab09-41a5-a2c1-b7a1f627252c"> согласно
                инструкциям</a>.</p>
        <InlineItem>
            <div>
                <InlineItem><FaCheckCircle/><strong>Когда стоит использовать кнопку:</strong></InlineItem>
                <ul>
                    <li> Гость сообщает о том, что не заедет, но при этом не хочет отменить бронь самостоятельно (при
                        этом бронь
                        не с нашего сайта).
                    </li>
                    <li>Время уже сильно после полуночи, гость не отвечает на звонки, а бронь не оплачена.</li>
                    <li>Вы подозреваете, что бронь фейковая.</li>
                </ul>
            </div>
            <div>
                <InlineItem><FaTimesCircle/><strong>Когда не стоит использовать кнопку:</strong></InlineItem>
                <ul>
                    <li> У вас произошел овербукинг, и вы отправили бронь в другой объект (т.к. в таком случае номер
                        снова
                        поступит в продажу)
                    </li>
                </ul>
            </div>
        </InlineItem>
    </div>
)


export const CancelBookingQuestionHoverIcon = () => {
    const showModal = () => (
        callModal(
            modalTemplates.alert({
                title: 'Как работает кнопка "Отмена"',
                text: <CancelInfoModal/>,
            })
        )
    )

    return (
        <AboutCancel onClick={showModal}>
            О кнопке «Отмена»
            <QuestionIcon/>
        </AboutCancel>
    )
}
