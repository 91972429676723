import React, {useCallback, useState} from 'react'
import {Table} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import {reservationSelectors} from '../../../../../Modules/reservation'
import {Item} from './Item'
import {useModalState} from '../../../../common/hooks/use-modal-state'
import {breakfastsSelectors} from '../../../../../Modules/breakfasts'
import {useActions} from '../../../../common/hooks/use-actions'
import * as servicesActions from '../../../../../Modules/reservation/actions/services'
import {ChangeBreakfastModal} from '../../../../reservation/common/EditBreakfastModal'

export const Content = ({
                            actions,
                            validationErrors,
                            availableActions,
                        }) => {
    const breakfastServices = useSelector(reservationSelectors.breakfastServices)
    const breakfastValueList = useSelector(breakfastsSelectors.breakfastValueList)
    const breakfastByPk = useSelector(breakfastsSelectors.breakfastByPk)
    const {updateBreakfastType, updateServiceDeliveryDate} = useActions(servicesActions)
    const availableDates = useSelector(
        reservationSelectors.availableBreakfastDates
    )

    const {
        isOpen: islModalOpen,
        open: openModal,
        close: closeModal,
    } = useModalState()

    const [selectedData, setSelectedData] = useState(null)

    const selectBreakfast = useCallback(breakfast => {
        setSelectedData({
            ...breakfast,
            qty: breakfast.quantity,
            breakfast: +breakfast.breakfast_pk,
            delivery_date: breakfast.breakfast_delivery_date,
            breakfastObj: breakfastByPk[+breakfast.breakfast_pk]
        })
        openModal()
    }, [openModal])

    const updateBreakfast = async ({pk, date}) => {
        updateBreakfastType({id: selectedData.id, value: pk})
        updateServiceDeliveryDate({id: selectedData.id, value: date})
        setSelectedData(null)
    }

    return (
        <Table style={{marginBottom: 0}}>
            <thead>
            <tr>
                <th>Дата доставки</th>
                <th>Завтрак</th>
                <th>Стоимость за штуку</th>
                <th>Количество</th>
                <th>Метод оплаты</th>
                <th
                    style={{
                        textAlign: 'right',
                    }}
                >
                    Действия
                </th>
            </tr>
            </thead>
            <tbody>
            {breakfastServices.map(item => (
                <Item
                    key={item.id}
                    data={item}
                    validationErrors={validationErrors[item.id] || {}}
                    actions={{...actions, edit: () => selectBreakfast(item)}}
                    availableActions={availableActions}
                />
            ))}
            {selectedData && <ChangeBreakfastModal
                isOpen={islModalOpen}
                hideModal={closeModal}
                breakfasts={breakfastValueList}
                save={updateBreakfast}
                availableDates={availableDates}
                editData={{
                    breakfast: selectedData.breakfast_pk,
                    delivery_date: selectedData.breakfast_delivery_date,
                    qty: selectedData.quantity,
                }}
            />}
            </tbody>
        </Table>
    )
}
