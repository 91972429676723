import styled from "styled-components";

export const Block = styled.div`
    position: relative;
    height: 235px;
`

export const SvgBlock = styled.div`
    border-radius: 50%;
    background-color: ${props => props.color}
    margin: 0 auto;
    text-align: center;
    padding: 2px;
    height: 32px;
    width: 32px;
    cursor: pointer;


    svg {
        margin: 0;
        width: 16px;
        height: 16px;
    }
`

export const WideBtn = styled.div`
    padding: 5px 10px;
    font-size: 12px;
    display: inline-block;
    color: #fff;
    background-color: #444;
    border-radius: 3px;
    line-height: 1.3;
    cursor: pointer;
    width: fit-content;
    height: fit-content;

    svg {
        margin-right: 5px;
        vertical-align: text-top;
    }
`

export const InfoBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        background-color: #f0f6ff;
        padding: 6px 8px;
        border-radius: 20px;
        margin: 0;

        strong {
            color: #428DFF;
        }

        svg {
            margin-right: 5px;
        }
    }

    svg {
        vertical-align: bottom;
        height: unset;
        width: unset;
    }

`
