import React, {useState} from "react";
import {Badge} from "react-bootstrap";
import styled from "styled-components";
import {Link} from "../common/Link";
import {useActions} from "../../../../common/hooks/use-actions";
import {newsActions} from "../../../../SpecialButtons/News/reducer";
import {FaTimes} from "react-icons/fa";
import moment from "moment";

const Block = styled.div`
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #D1D7E4;
    margin-bottom: 12px;
    line-height: 120%;

    div {
        margin-bottom: 8px;
    }

    svg {
        cursor: pointer;
        height: 16px;
    }
`

const Stack = styled.div`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    overflow: hidden;
    -webkit-line-clamp: ${props => props.show ? 'auto' : 3};
    margin: 8px 0;

    img {
        display: block;
        max-width: 350px;
        max-height: 200px;
        margin: 10px;
    }
     p, div {
         margin: 0;
     }
`

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    color: #B3BACA;
`

export const Item = ({item: {description, publication_at, categories, title, id}}) => {
    const {checkNewNews} = useActions(newsActions)
    const [showAll, setShowAll] = useState(false)

    const isLong = description.length > 150 || description.split(/\r\n|\r|\n/).length > 3

    return (
        <Block>
            <Flex>
                {moment(publication_at).format('DD.MM.YYYY HH:mm')}
                <FaTimes onClick={() => checkNewNews({id})}/>
            </Flex>
            <div>
                {categories.map((it) => (
                    <Badge style={{backgroundColor: it.color, marginRight: 5, marginBottom: 5}}
                           key={it.id + '_' + id}>{it.name}</Badge>
                ))}
            </div>
            <strong>{title}</strong>
            <Stack show={showAll} dangerouslySetInnerHTML={{__html: description}}/>
            {isLong && (showAll
                    ? <Link onClick={() => setShowAll(false)}>Скрыть→</Link>
                    : <Link onClick={() => setShowAll(true)}>Читать полностью →</Link>
            )}
        </Block>
    )
}
