import React, {useEffect, useState} from 'react'
import {BunkBedIconTip} from "../../../../common/IconsWithTooltips";
import {Button} from "../../../../buttons";
import {callModal, modalTemplates} from "../../../../dynamic-modal";
import {modalActions} from "../../../../dynamic-modal/templates/confirm";
import Cookies from "js-cookie";
import moment from "moment/moment";

const COOKIE_NAME = 'second_tier_checked'

export const CleanSecondTierBtn = ({booking}) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(secondTierChecked(booking.pk))
    }, [booking]);

    const setMark = async (e) => {
        e.stopPropagation()
        const {action} = await callModal(
            modalTemplates.confirm({
                text: 'Вы уверены, что назначили номер для подготовки второго яруса горничной у данной брони',
                buttons: ['Нет', 'Да'],
            })
        )

        if (action === modalActions.confirm) {
            const {check_out_time, end, pk} = booking
            const checked = Cookies.getJSON(COOKIE_NAME) || {}
            const expires = moment(end + ' ' + check_out_time, 'YYYY-MM-DD HH:mm').toDate()
            Cookies.set(COOKIE_NAME, {...checked, [pk]: true}, {expires})
            setChecked(true)
        }
    }
    return (
        <Button
            size="xs"
            type="warning"
            style={{margin: '4px 0 0', padding: 3, width: 'fit-content'}}
            disabled={checked}
            onClick={e => setMark(e)}
        >
            <BunkBedIconTip checked={checked}/>
        </Button>
    )
}
export const secondTierChecked = (pk) => {
    const checked = Cookies.getJSON(COOKIE_NAME) || {}
    return checked[pk]
}
