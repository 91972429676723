import * as types from './types'
import {ccAPI} from "../../../Modules/api/ccAPI";
import Cookies from 'js-cookie';

const loadRequest = () => ({
    type: types.LOAD_REQUEST,
})

const loadSuccess = ({items}) => ({
    type: types.LOAD_SUCCESS,
    payload: {items},
})

const loadFailure = () => ({
    type: types.LOAD_FAILURE,
})

export const load = (lcode) => async (dispatch) => {
    dispatch(loadRequest())
    try {
        const items = await ccAPI.getStickers({lcode})

        dispatch(loadSuccess({items}))
    } catch {
        dispatch(loadFailure())
    }
}

export const updateSticker = (data) => async (dispatch) => {
    try {
        const res = await ccAPI.updateSticker(data)

        dispatch({type: types.UPDATE_STICKER_STATUS, payload: res})
    } catch {
        dispatch(loadFailure())
    }
}

export const createSticker = (data) => async (dispatch) => {
    try {
        const payload = await ccAPI.createSticker(data)

        dispatch({type: types.ADD_STICKER, payload})
    } catch {
        dispatch(loadFailure())
    }
}

export const hideStickers = (payload) => async (dispatch) => {
    Cookies.set('hide_stickers', payload)
    dispatch({type: types.HIDE, payload})
}
