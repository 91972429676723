import React from "react";

export const BunkBedIcon = () => <Icon style={{position: "absolute", padding: 2}}/>
export const BunkBedIconLegend = ({style, color}) => <Icon style={style} color={color}/>

const Icon = ({style, color = '#ffff'}) => (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"
                                viewBox="0 0 96 96" id="bunk-bed"
                                style={style}
>
    <path stroke={color} strokeWidth="5"
          d="M14 79L81 79C83.7614 79 86 76.7614 86 74 86 71.2386 83.7614 69 81 69L48.4872 69 14 69C11.2386 69 9 71.2386 9 74 9 76.7614 11.2386 79 14 79zM14 31L81 31C83.7614 31 86 28.7614 86 26 86 23.2386 83.7614 21 81 21L48.4872 21 14 21C11.2386 21 9 23.2386 9 26 9 28.7614 11.2386 31 14 31zM13.5 69L25.5 69C27.9853 69 30 66.9853 30 64.5 30 62.0147 27.9853 60 25.5 60L19.7692 60 13.5 60C11.0147 60 9 62.0147 9 64.5 9 66.9853 11.0147 69 13.5 69z"></path>
    <path stroke={color} strokeLinecap="round" strokeWidth="5"
          d="M9 85L9 17M86 85L86 12M69 66L69 34M69 43L55 43M69 57L55 57M54 66L54 34"></path>
    <path stroke={color} strokeWidth="5"
          d="M13.5 21L25.5 21C27.9853 21 30 18.9853 30 16.5C30 14.0147 27.9853 12 25.5 12L19.7692 12L13.5 12C11.0147 12 9 14.0147 9 16.5C9 18.9853 11.0147 21 13.5 21Z"></path>
</svg>)
