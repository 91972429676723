import {createSelector} from 'reselect'

export const everything = state => state.session

export const adminName = state => everything(state).admin
export const adminId = state => everything(state).adminID
export const isAuthorized = state => everything(state).isAuthorized
export const lcode = state => everything(state).lcode
export const lcodeName = state => everything(state).lcodeName
export const gridScale = state => everything(state).gridScale

export const email = state => everything(state).email

export const lcodeObj = createSelector(everything, everything => everything.lcodeObj)

export const adminInfo = createSelector(everything, everything => ({
        username: everything.admin,
        email:everything.email,
        full_name: everything.username  ,
        short_name: everything.admin
}))

