import React, {useCallback} from 'react'
import {paymentTypesNoBank} from '../../../../../Modules/helpers/paymentTypes'
import {Cell, ReadOnlyCell} from '../../../common/Cell'
import {Input, Select} from '../../../../form-stuff'
import {Button, ButtonGroup} from '../../../../buttons'
import {day} from '../../../../../Modules/formatters/date'
import {ServiceItemPrice} from '../../../../common/ItemPrice'

export const Item = ({
                         data,
                         availableDates,
                         validationErrors,
                         availableActions,
                         actions,
                     }) => {
    const {id} = data

    const setPaymentType = useCallback(
        value => actions.setPaymentType({id, value}),
        [id, actions]
    )

    const setPrice = useCallback(value => actions.setPrice({id, value}), [
        id,
        actions,
    ])

    const copy = useCallback(
        value =>
            actions.copy({
                id,
                newDate: availableDates[0],
            }),
        [id, availableDates, actions]
    )

    const remove = useCallback(value => actions.delete({id}), [id, actions])
    const refund = useCallback(value => actions.refund({id}), [id, actions])

    const isPayed = Boolean(data.payment_date)

    const copyCondition = availableActions.copy && availableDates.length > 0
    const updateCondition = availableActions.update && !isPayed
    const deleteCondition = availableActions.delete && !isPayed
    const refundCondition = availableActions.refund && isPayed

    const dataCells = !updateCondition ? (
        <>
            <ReadOnlyCell>{day(data.date)}</ReadOnlyCell>
            <ReadOnlyCell>
                <ServiceItemPrice item={data}/>
            </ReadOnlyCell>
            <ReadOnlyCell>{data.payment_type}</ReadOnlyCell>
        </>
    ) : (
        <>
            <ReadOnlyCell>{day(data.date)}</ReadOnlyCell>
            <Cell>
                <Input
                    type="number"
                    placeholder="Введите стоимость"
                    value={data.price}
                    onChange={setPrice}
                    hasError={validationErrors['price']}
                    noMargin
                />
            </Cell>
            <Cell>
                <Select
                    value={data.payment_type}
                    options={paymentTypesNoBank}
                    onChange={setPaymentType}
                    hasError={validationErrors['payment_type']}
                    noMargin
                />
            </Cell>
        </>
    )

    return (
        <tr>
            {dataCells}
            <Cell>
                <ButtonGroup align="right">
                    {copyCondition && (
                        <Button type="success" onClick={copy}>
                            Копировать
                        </Button>
                    )}
                    {deleteCondition && (
                        <Button type="danger" onClick={remove}>
                            Удалить
                        </Button>
                    )}
                    {refundCondition && (
                        <Button type="danger" onClick={refund}>
                            Возврат
                        </Button>
                    )}
                </ButtonGroup>
            </Cell>
        </tr>
    )
}
