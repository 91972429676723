import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {newsActions, newsSelectors} from './reducer'
import styled from 'styled-components'
import {Pagination, Table} from 'react-bootstrap'
import {useActions} from '../../common/hooks/use-actions'
import {Button} from '../../buttons'
import Select, {components} from 'react-select'
import {callModal, modalTemplates} from '../../dynamic-modal'
import {modalActions} from '../../dynamic-modal/templates/confirm'
import {NewsTable} from "./NewsTable";

const page_size = 10

const Block = styled.div`
    padding: 100px;
`

const SelectedItem = styled.div`
    background-color: ${props => props.color};

    & > div {
        height: 100%;
    }
`
const buildCategoriesList = list => list.map(({value}) => ({id: value}))


const MultiValueLabel = (props) => {
    return (
        <SelectedItem color={props.data.color}>
            <components.MultiValueLabel {...props} />
        </SelectedItem>
    );
};

const MultiValueRemove = (props) => {
    return (
        <SelectedItem color={props.data.color}>
            <components.MultiValueRemove {...props} />
        </SelectedItem>
    );
};

const work = "work"
const completed = "completed"

export const News = () => {
    const list = useSelector(newsSelectors.items)
    const count = useSelector(newsSelectors.count)
    const categories = useSelector(newsSelectors.categoryOptions)
    const categoriesIds = useSelector(newsSelectors.categoriesIds)
    const {load, checkNews, getCategories, loadNewNews, readAll} = useActions(newsActions)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [selectedCategories, setSelectedCategories] = useState(null)
    const [urlCategories, setUrlCategories] = useState("")
    const [showNotMarked, setShowNotMarked] = useState(false)
    const [showInWork, setShowInWork] = useState(false)
    const [status, setStatus] = useState(completed)

    useEffect(() => {
        getCategories()
        loadNewNews()
    }, [getCategories, loadNewNews])

    useEffect(() => {
        const num = Math.ceil(count / page_size)
        setPageCount(num)
        const pages = Array.from({length: num}, (_, i) => i + 1)
        setPages(pages)
    }, [count])

    useEffect(() => {
        load({
            page,
            page_size,
            is_not_marked: showNotMarked || null,
            status
        }, urlCategories)
    }, [page, urlCategories, showNotMarked, load, status])


    const selectCategory = (val = []) => {
        setPage(1)
        if (val) {
            const url = new URL(window.location);
            val.forEach(({value}) => url.searchParams.append('category', value))
            setUrlCategories(url.search)
            const categories = buildCategoriesList(val)
            setSelectedCategories({categories})
        } else {
            setUrlCategories("")
            setSelectedCategories(null)
        }
    }

    const readAllHandler = async () => {
        const {action} = await callModal(modalTemplates.confirm({
            title: 'Подтвердите',
            text: 'Вы уверены, что хотите пометить все новости прочитанными?',
            buttons: ['Нет', 'Да'],
        }))
        if (action === modalActions.confirm)
            if (selectedCategories)
                readAll(selectedCategories)
            else readAll(categoriesIds)
    }
    return (<Block>
        <Select
            placeholder="Выберите категории новостей"
            isMulti
            options={categories}
            components={{MultiValueLabel, MultiValueRemove}}
            onChange={selectCategory}
            styles={{
                container: (base) => ({
                    ...base,
                    paddingBottom: 20,
                })
            }}
        />
        <Table>
            <thead>
            <tr>
                <th width="20%">
                    {status === work
                        ? <Button type="warning" onClick={() => {
                            setStatus(completed)
                        }}>
                            Показать завершенные
                        </Button>
                        : <Button type="warning" onClick={() => {
                            setStatus(work)
                            setShowNotMarked(false)
                        }}>
                            Показать в работе
                        </Button>
                    }
                </th>
                {status === work
                    ? <>
                        <th/>
                        <th/>
                    </>
                    : <>
                        <th style={{textAlign: 'right'}}>
                            {showNotMarked
                                ? <Button
                                    onClick={() => setShowNotMarked(false)}>Показать все</Button>
                                : <Button
                                    onClick={() => {
                                        setShowNotMarked(true)
                                        setStatus('')

                                    }}>Показать непрочитанные</Button>
                            }
                        </th>
                        <th width="20%"><Button onClick={readAllHandler}>Пометить все прочитанным
                        </Button>
                        </th>
                    </>
                }
            </tr>
            </thead>
            <NewsTable
                list={list}
                setRead={checkNews}
                showControls={status !== work}
            />
        </Table>

        <Pagination>
            <Pagination.First onClick={() => setPage(1)}/>
            <Pagination.Prev onClick={() => setPage(prev => prev - 1 || 1)}/>
            {pages.map(it => <Pagination.Item
                key={'page_' + it}
                active={page === it}
                onClick={() => setPage(it)}
            >{it}</Pagination.Item>)}
            <Pagination.Next onClick={() => {
                if (page + 1 <= count) setPage(prev => prev + 1)
            }}/>
            <Pagination.Last onClick={() => setPage(pageCount)}/>
        </Pagination>
    </Block>)
}

