import {request} from "./request";

export const sendCode = ({
                               booking_number,
                             booking_id
                            }) =>
    request({
        url: '/get-passcode-and-notify',
        method: "post",
        data: {
            booking_id,
            send_message: true
        },
        log: true,
    }).then((res) => res)

export const getCode = ({
                            booking_id
                         }) =>
    request({
        url: '/get-passcode-and-notify',
        method: "post",
        data: {
            booking_id
        },
        log: true,
    }).then((res) => res)
