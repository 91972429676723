import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {stickersActions, stickersSelectors} from "../../../../Stickers/reducer";

import {FaEye, FaEyeSlash, FaListUl, FaPlus} from "react-icons/fa";
import {changeStatusConfirmation} from "../../../../Stickers/components/ChangeStatusConfirmation";
import {useActions} from "../../../../common/hooks/use-actions";
import {useHistory} from "react-router-dom";
import {archive} from "../../../../Stickers/reducer/selectors";
import {Block, InfoBlock, SvgBlock} from "../../../../Stickers/components/StyledComponents";
import {Lightning} from "../../../../Stickers/components/LightningIco";
import {Sticker} from "../../../../Stickers/components/Sticker";
import Slider from "react-slick";
import {BtnBlock, SampleNextArrow, SamplePrevArrow} from "../../../../Stickers/components/SwiperComponenets";
import {createStickerModal} from "../../../../Stickers/components/AddStickerModal";
import {sessionSelectors} from "../../../../../Modules/session";
import {Template} from "../common/Template";
import {DarkButton} from "../common/DarkButton";

const colors = [
    {bg: "#EBC5FB"},
    {bg: "#D5FBC5"},
    {bg: "#fafba3"},
    {bg: "#A5A3FB"},
    {bg: "#B6E1FF"},
    {bg: "#FBE9C5"},
]

export const Stickers = () => {
    const lastFiveStickers = useSelector(stickersSelectors.lastFiveStickers)
    const countActiveStickers = useSelector(stickersSelectors.countActiveStickers)
    const isHidden = useSelector(stickersSelectors.isHidden)
    const adminInfo = useSelector(sessionSelectors.adminInfo)
    const lcode = useSelector(sessionSelectors.lcode)
    const {updateSticker, hideStickers, createSticker} = useActions(stickersActions)
    const [list, setList] = useState({})
    const [extended, setExtended] = useState(null)

    const [updateCount, setUpdateCount] = useState(0);

    let sliderRef = useRef(null);

    const settings = {
        infinite: false,
        accessibility: true,
        arrows: true,
        draggable: true,
        slidesToScroll: 1.2,
        slidesToShow: 2.2,
        speed: 500,
        swipe: true,
        swipeToSlide: true,
        focusOnSelect: true,
        afterChange: () => {
            setUpdateCount(updateCount + 1)
            setExtended(null)
        },
    };

    let history = useHistory();


    useEffect(() => {
        setList(lastFiveStickers.reduce((a, b, index) => {
            a[index] = {...b, ...colors[index], index}
            return a
        }, {}))
    }, [lastFiveStickers]);

    const goToArchive = ({id}) => {
        changeStatusConfirmation(archive).then((res) => {
            if (res) {
                updateSticker({id, category: archive})
            }
        })
    }
    const goToList = () => history.push('/stickers')

    const extend = key => {
        setExtended(key)
        sliderRef.slickGoTo(key)
    }

    const addSticker = async () => {
        const content = await createStickerModal()
        if (content)
            await createSticker({admin: adminInfo, ...content, lcodes: [lcode]})
    }

    if (!lastFiveStickers.length)
        return <div/>

    return (
        <>
            <Template header='Напоминания'
                      headerControl={<DarkButton
                          onClick={addSticker}>
                          <FaPlus style={{verticalAlign: 'middle'}}/>
                          Добавить новое
                      </DarkButton>}>
                <InfoBlock style={{position: 'relative', marginBottom: 16}}>
                    <p><Lightning/>У вас есть активные напоминания! <strong>{countActiveStickers} шт.</strong></p>
                    <div style={{display: 'flex', gap: 5}}>
                        <SvgBlock color="#B3BACA"> <FaListUl type="warning" color="white"
                                                             onClick={goToList}/></SvgBlock>
                        {isHidden
                            ? <SvgBlock color="#428DFF"><FaEye color="white"
                                                               onClick={() => hideStickers(false)}/></SvgBlock>
                            : <SvgBlock color="#428DFF"><FaEyeSlash color="white"
                                                                    onClick={() => hideStickers(true)}/></SvgBlock>
                        }
                    </div>
                </InfoBlock>
                {!isHidden &&
                    <Block>
                        <BtnBlock>
                            <SamplePrevArrow onClick={() => sliderRef.slickPrev()}/>
                            <SampleNextArrow onClick={() => sliderRef.slickNext()}/>
                        </BtnBlock>
                        <Slider {...settings} ref={slider => {
                            sliderRef = slider;
                        }}>
                            {Object.entries(list).map(([key, it]) => {
                                    return (
                                        <Sticker bg={it.bg}
                                                 id={it.id}
                                                 key={it.id}
                                                 index={key}
                                                 content={it.content}
                                                 goToArchive={goToArchive}
                                                 goToList={goToList}
                                                 extended={extended === key}
                                                 setExtended={extend}
                                        />
                                    )
                                }
                            )}
                            <div></div>
                        </Slider>
                    </Block>
                }
            </Template>
        </>
    )
}

