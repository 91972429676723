import React from 'react'
import {Content} from './Content'
import {canceledWarningsSelectors} from '../../../reducers/canceled-warnings'
import {warningsKind} from '../../../../../Constants/warning-types'
import {useSelector} from 'react-redux'

export const BreakfastWarnings = () => {
    const items = useSelector(canceledWarningsSelectors.breakfastWarnings)

    const handelCheck = (booking) => {
        let {warnings} = booking
        warnings = warnings.map(it => {
            if (it.kind === warningsKind.prepaid_breakfasts_left) {
                it.action_performed = true
            }
            return it
        })
        booking.warnings = warnings
        return booking
    }

    return (
        <Content
            items={items}
            handelCheck={handelCheck}
            header='Удаленные брони, у которых есть оплаченные завтраки'
            text='Указанные ниже брони удалены из шахматки, но имеют активные предоплаченные завтраки. Пожалуйста,
        свяжитесь с бухгалтерией на предмет удаления этих завтраков. В противном случае они будут приготовлены и
        доставлены.'
        />
    )
}
