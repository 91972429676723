import {callConfirm} from "../../dynamic-modal";
import {relevant} from "../reducer/selectors";

export const changeStatusConfirmation = async (category) => {
    const text = category === relevant ? "Вы действительно хотите вернуть напоминание в работу?" : "Вы действительно хотите перенести напоминание в архив?"
    return await callConfirm({
        title: 'Подтверждение',
        text,
    })
}
