import * as types from './types'
import Cookies from 'js-cookie';

const getInitialState = () =>{
    return {
    items: [],
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    hidden: Cookies.getJSON('hide_stickers')
}}

export const reducer = (state = getInitialState(), action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_REQUEST:
            return {
                ...getInitialState(),
                isLoading: true,
            }

        case types.LOAD_SUCCESS:
            return {
                ...state,
                items: payload.items,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.UPDATE_STICKER_STATUS:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                items: state.items.map((it) => {
                    if (it.id === payload.id)
                        it = payload
                    return it
                })
            }

        case types.ADD_STICKER:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                items: [...state.items, payload]
            }

        case types.HIDE:
            return {
                ...state,
                hidden: payload
            }

        case types.RESET:
            return getInitialState()

        default:
            return state
    }
}
