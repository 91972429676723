import React, {useEffect} from "react";
import {useActions} from "../common/hooks/use-actions";
import {stickersActions} from "./reducer";
import {Content} from "./components/Content";
import styled from 'styled-components'
import {useSelector} from "react-redux";
import {sessionSelectors} from "../../Modules/session";

const Block = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px 40px;
    height: 100%;
`

export const Stickers = () => {
    const {load} = useActions(stickersActions)
    const lcode = useSelector(sessionSelectors.lcode)

    useEffect(() => {
        load(lcode)
    }, []);

    return (
        <Block>
            <h2>Напоминания</h2>
        <Content/>
        </Block>
    )
}
