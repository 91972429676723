import {createSelector} from 'reselect'
import {dayTypes, MODES, serviceTypes} from '../helpers/types'
import {getCheckInMultiplier, getCheckOutMultiplier,} from '../helpers/services/check-times'
import {parseCheckTime} from '../helpers/base-data/check-times'
import {isReservationArchivedModular} from '../helpers/base-data/is-archived'
import {getDiscountedItemTotal, getItemTotal, getPrintedItemTotal,} from '../helpers/item-selectors'
import moment from 'moment'
import {sortDays} from './sort-days'
import {filters} from '../helpers/converters'
import {currentDateSelectors} from '../current-date'
import paymentTypes from '../helpers/paymentTypes'
import {BN_VARIANTS} from '../../Constants/booking-number-variants'
import {breakfastLimitTime} from '../settings/selectors'
import {lcode as currentLcode} from '../session/selectors'
import {breakfast_disabled_dates} from "./constants";
import {breakfastRestrictionsObj} from "../breakfastRestriictions/selectors";

function calculateItemsTotal({days, services}) {
    let total = 0

    for (let day of days) {
        total += getPrintedItemTotal(day)
    }

    for (let service of services) {
        total += getPrintedItemTotal(service)
    }

    return total
}

export const everything = state => state.reservation

export const pk = state => everything(state).pk
export const bookingNumber = state => everything(state).booking_number

export const isWithoutReservation = state =>
    BN_VARIANTS.WITHOUT_RESERVATION.includes(bookingNumber(state))

export const isReady = state => everything(state).isReady

export const isWubook = state => !isReady(state)

export const mode = state => everything(state).mode

export const isModeSelected = state => mode(state) !== MODES.INITIAL

export const isNotRefundMode = state => mode(state) !== MODES.REFUND

export const serviceQuantityData = state => everything(state).serviceQuantityData

export const modes = createSelector(mode, mode => ({
    initial: mode === MODES.INITIAL,
    create: mode === MODES.CREATE,
    edit: mode === MODES.EDIT,
    wubook: mode === MODES.WUBOOK,
    refund: mode === MODES.REFUND,
    readonly: mode === MODES.READONLY,
}))

export const isEditable = createSelector(modes, modes => {
    return modes.create || modes.edit || modes.wubook
})

export const reservationStart = state => everything(state).start

export const reservationEnd = state => everything(state).end

export const checkInTime = state => everything(state).check_in_time

export const checkOutTime = state => everything(state).check_out_time

export const lcode = state => everything(state).lcode

export const checkInTimeObject = createSelector(checkInTime, parseCheckTime)

export const checkOutTimeObject = createSelector(checkOutTime, parseCheckTime)

export const daysUnsorted = state => everything(state).reserved_days

export const days = createSelector(daysUnsorted, daysUnsorted =>
    daysUnsorted.sort(sortDays)
)

export const discountedDays = createSelector(days, days =>
    days.filter(filters.onlyDiscounted)
)

export const discountedDaysIds = createSelector(
    discountedDays,
    discountedDays => discountedDays.map(day => day.id)
)

export const daysRooms = createSelector(days, days => days.map(day => day.room))

export const newDays = createSelector(days, days =>
    days.filter(day => day.type === dayTypes.new)
)

export const notPayedDays = createSelector(days, days =>
    days.filter(day => day.type === dayTypes.notPayed)
)

export const payedDays = createSelector(days, days =>
    days.filter(day => day.type === dayTypes.payed)
)

export const daysDates = createSelector(days, days => days.map(day => day.date))

export const firstDay = state => {
    const daysValue = days(state)
    return daysValue[0] || null
}

export const dayPrices = state => everything(state).dayPrices

export const firstDayPrice = state => {
    const priceList = dayPrices(state)
    const first = firstDay(state)
    if (first) {
        const reserved = priceList[first.date]
        const prevDate = moment(first.date)
            .add(-1, 'days')
            .format('YYYY-MM-DD')
        const additional = dayPrices(state)[prevDate]
        return {
            reserved,
            additional
        }
    }
    return {}
}

export const lastDay = state => {
    const daysValue = days(state)
    return daysValue[daysValue.length - 1] || null
}

export const lastDayPrice = state => {
    const priceList = dayPrices(state)
    const last = lastDay(state)
    if (last) {
        const reserved = priceList[last.date]
        const nextDate = moment(last.date)
            .add(1, 'days')
            .format('YYYY-MM-DD')
        const additional = dayPrices(state)[nextDate]
        return {
            reserved,
            additional
        }
    }
    return {}
}

export const firstDayDate = state => firstDay(state)?.date ?? null

export const lastDayDate = state => lastDay(state)?.date ?? null

export const reservationDates = createSelector(
    daysDates,
    reservationEnd,
    (daysDates, reservationEnd) => [...daysDates, reservationEnd]
)

export const initialData = state => everything(state).initialData

export const availableDates = createSelector(
    daysDates,
    currentDateSelectors.yesterday,
    (dates, yesterday) => {
        return dates.filter(date => {
            return moment(date).isSameOrAfter(yesterday, 'day')
        })
    }
)

export const availableBreakfastDatesByTime = createSelector(
    reservationDates,
    currentDateSelectors.today,
    currentDateSelectors.tomorrow,
    currentDateSelectors.hour,
    currentDateSelectors.minute,
    breakfastLimitTime,
    (dates, today, tomorrow, hour, minute, breakfastLimitTime) => {
        const brTime = moment(`${breakfastLimitTime.hour}:${breakfastLimitTime.munutes}`, "HH:mm")
        return dates.filter(date => {
            if (moment(date).isSameOrBefore(today)) {
                return false
            } else {
                const current = moment(`${hour}:${minute}`, "HH:mm")
                return date === tomorrow ? current.isBefore(brTime) : true
            }
        })
    }
)

export const availableBreakfastDates = createSelector(
    availableBreakfastDatesByTime,
    breakfastRestrictionsObj,
    (dates, restrictions) => dates
        .filter(date => !breakfast_disabled_dates.includes(date))
        .filter(it => !restrictions.includes(it))
)

export const initialDatesUnsorted = state => initialData(state).reserved_days.map(it => it.date)

export const services = state => everything(state).additional_services

export const breakfastServices = createSelector(services, services =>
    services.filter(service => service.service_type === 'breakfast').sort((a, b) => new Date(a.breakfast_delivery_date) - new Date(b.breakfast_delivery_date))
)

export const notBreakfastServices = createSelector(services, services =>
    services.filter(service => service.service_type !== 'breakfast')
)

export const hasEarlyCheckIn = createSelector(services, services =>
    services.filter(service => service.service === 'Ранний заезд')
)

export const availableBreakfastDatesForEdit = createSelector(
    initialData,
    breakfastServices,
    currentDateSelectors.today,
    (initial, services, today,) => {
        const days = services.map(it => it.breakfast_delivery_date)
        return days.filter(date => {
            return !moment(date).isSameOrBefore(today);
        })
    }
)

export const passport = state => everything(state).passport

export const discountedServices = createSelector(services, services =>
    services.filter(filters.onlyDiscounted)
)

export const discountedServicesIds = createSelector(
    discountedServices,
    discountedServices => discountedServices.map(day => day.id)
)

export const serviceServices = createSelector(services, services =>
    services.filter(service => service.service_type === 'service')
)

export const upgradeServices = createSelector(services, services =>
    services.filter(service => service.service_type === 'upgrade')
)

export const parkingServices = createSelector(services, services =>
    services.filter(service => service.service_type === 'parking')
)

export const checkInService = createSelector(
    services,
    services =>
        services.find(service => service.service_type === 'checkin') || null
)

export const checkOutService = createSelector(
    services,
    services =>
        services.find(service => service.service_type === 'checkout') || null
)

const createCheckPriceCombiner = getCheckMultiplier => (timeObject, day) => {
    const {additional, reserved} = day
    const multiplier = getCheckMultiplier(timeObject)
    const price = multiplier === 1 ? additional : reserved

    return price * multiplier
}

const createCheckOutPriceCombiner = getCheckMultiplier => (timeObject, day) => {
    const {additional: price} = day
    const multiplier = getCheckMultiplier(timeObject)
    return price * multiplier
}

export const checkInPrice = createSelector(
    checkInTimeObject,
    firstDayPrice,
    createCheckPriceCombiner(getCheckInMultiplier)
)

export const checkOutPrice = createSelector(
    checkOutTimeObject,
    lastDayPrice,
    createCheckOutPriceCombiner(getCheckOutMultiplier)
)

export const commonServicesRaw = state => everything(state).services
export const commonServices = createSelector(commonServicesRaw, services =>
    services.filter(service => service.name !== 'Парковка')
)

export const specialServicesRaw = state => everything(state).specialServices
export const specialServices = createSelector(specialServicesRaw, services =>
    services.filter(service => service.name !== 'Завтрак')
)

export const availableServices = createSelector(
    commonServices,
    specialServices,
    (common, special) => ({
        common,
        special,
    })
)

export const combinedServices = createSelector(
    availableServices,
    ({common, special}) => [...common, ...special]
)

export const specialServicesAll = state => state.specialServiceTypes?.items || []


export const serviceLoyaltyAllowabilityMap = createSelector(
    specialServicesAll,
    services => {
        return services.reduce(
            (acc, service) => {
                acc[service.name] = service.is_loyalty_allowed
                return acc
            }, {}
        )
    }
)

export const items = createSelector(days, services, (days, services) => ({
    days,
    services,
}))

export const daysWithoutRefundId = createSelector(days, days =>
    days
        .filter(day => !day.refund_id)
        .filter(day => {
            return day.payment_date && day.payment_type
        })
)

export const servicesWithoutRefundId = createSelector(
    services,
    serviceLoyaltyAllowabilityMap,
    (services, allowabilityMap) =>
        services
            .filter(service => !service.refund_id)
            .filter(service => {
                return service.payment_type
            })
            .filter(service => allowabilityMap[service.service])
)

export const itemsWithoutRefundId = createSelector(
    daysWithoutRefundId,
    servicesWithoutRefundId,
    (days, services) => ({days, services})
)

export const itemsWithoutRefundIdIds = createSelector(
    itemsWithoutRefundId,
    ({days, services}) => {
        const allIds = [
            ...days.map(day => day.id),
            ...services.map(service => service.id),
        ]

        return allIds.sort()
    }
)

export const itemsWithoutRefundIdTotal = createSelector(
    itemsWithoutRefundId,
    ({days, services}) => calculateItemsTotal({days, services})
)

export const upgradeableDaysDates = createSelector(
    availableDates,
    upgradeServices,
    (availableDates, upgrades) => {
        const upgradesDates = upgrades.map(upgrade => upgrade.date)

        const upgradesDatesMap = upgradesDates.reduce((acc, date) => {
            acc[date] = true
            return acc
        }, {})

        return availableDates.filter(date => !upgradesDatesMap[date])
    }
)

export const phoneNumber = state => everything(state).guest_phone

export const refundedItems = state => everything(state).refundedItems

export const wasLoyaltyUsed = createSelector(
    days,
    services,
    (days, services) => {
        for (let day of days) {
            if (day.refund_id) return true
        }

        for (let service of services) {
            if (service.refund_id) return true
        }

        return false
    }
)

export const paidDays = createSelector(days, days =>
    days.filter(day => {
        return day.type === dayTypes.payed
    })
)

export const lastPaidDay = createSelector(paidDays, days => days[days.length - 1]?.date)

export const paidServices = createSelector(services, services =>
    services.filter(service => {
        return Boolean(service.payment_date)
    })
)

export const notPaidDays = createSelector(days, days =>
    days.filter(day => day.type === dayTypes.new)
)

export const notPaidServices = createSelector(services, services =>
    services.filter(service => !Boolean(service.payment_date))
)

const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
}

export const hasNoDistinctPaymentType = createSelector(notPaidServices, notPaidDays, (services, days) =>
    [...services, ...days]
        .filter(it => it.payment_date && it.payment_type)
        .map(({payment_type}) => payment_type)
        .filter(it => it !== paymentTypes.bank)
        .filter(onlyUnique).length > 1
)

export const paidItems = createSelector(
    paidDays,
    paidServices,
    (days, services) => ({days, services})
)

export const paidDaysWithoutRefundId = createSelector(paidDays, paidDays =>
    paidDays.filter(day => !day.refund_id)
)

export const paidServicesWithoutRefundId = createSelector(
    paidServices,
    serviceLoyaltyAllowabilityMap,
    (paidServices, allowabilityMap) =>
        paidServices
            .filter(service => !service.refund_id)
            .filter(service => allowabilityMap[service.service])
)

export const paidItemsWithoutRefundId = createSelector(
    paidDaysWithoutRefundId,
    paidServicesWithoutRefundId,
    (days, services) => ({days, services})
)

export const areTherePaidItemsWithoutRefundId = createSelector(
    paidItemsWithoutRefundId,
    ({days, services}) => {
        return days.length > 0 || services.length > 0
    }
)

export const areTherePaidItemsWithoutRefundIdAndReady = createSelector(
    isReady,
    paidItemsWithoutRefundId,
    (isReady, {days, services}) => {
        return isReady && days.length > 0 || isReady && services.length > 0
    }
)


export const paidDaysWithRefundId = createSelector(paidDays, paidDays =>
    paidDays.filter(day => day.refund_id)
)

export const paidServicesWithRefundId = createSelector(
    paidServices,
    paidServices => paidServices.filter(service => service.refund_id)
)

export const paidItemsWithRefundId = createSelector(
    paidDaysWithRefundId,
    paidServicesWithRefundId,
    (days, services) => ({days, services})
)

export const areTherePaidItemsWithRefundId = createSelector(
    paidItemsWithRefundId,
    ({days, services}) => {
        return days.length > 0 || services.length > 0
    }
)

export const prePayedBreakfast = state => everything(state).prePayedBreakfast

export const prePayedBreakfastExtended = createSelector(
    prePayedBreakfast,
    isReady,
    (br, isReady) => {
        return br?.map(it => ({...it, prepaid: !isReady}))
    }
)


export const purchasedItems = createSelector(
    days,
    services,
    prePayedBreakfastExtended,
    isReady,
    (days, services, breakfasts = [], ready) => {
        const purchasedDays = []
        const purchasedServices = []
        let purchasedBreakfasts = []

        for (let day of days) {
            if (!day.payment_date || !day.payment_type) {
                continue
            }

            if (!ready && day.payment_type === paymentTypes.bank && day.type === dayTypes.payed && !day.refund_id) purchasedDays.push(day)
            if (day.type === dayTypes.new) purchasedDays.push(day)
            if (day.type === dayTypes.notPayed) purchasedDays.push(day)
        }

        for (let service of services) {
            if (!service.payment_type) {
                continue
            }

            if (!service.payment_date) purchasedServices.push(service)
        }

        if (!ready)
            purchasedBreakfasts = breakfasts || []

        return {
            days: purchasedDays,
            services: [...purchasedServices, ...purchasedBreakfasts],
        }
    }
)

export const purchasedItemsIds = createSelector(
    purchasedItems,
    ({days, services}) => {
        const allIds = [
            ...days.map(day => day.id),
            ...services.map(service => service.id),
        ]

        return allIds.sort()
    }
)

export const areTherePurchasedItems = createSelector(
    purchasedItems,
    ({days, services}) => days.length > 0 || services.length > 0
)

export const purchasedItemsWithAllowableLoyalty = createSelector(
    purchasedItems,
    serviceLoyaltyAllowabilityMap,
    ({days, services}, allowabilityMap) => {
        const allowedServices = services.filter(service => allowabilityMap[service.service])
        return {
            days,
            services: allowedServices,
        }
    }
)

export const areTherePurchasedItemsWithAllowableLoyalty = createSelector(
    purchasedItemsWithAllowableLoyalty,
    ({days, services}) => {
        return days.length > 0 || services.length > 0
    }
)

export const purchaseTotal = createSelector(
    purchasedItems,
    ({days, services}) => calculateItemsTotal({days, services})
)

export const refundedItemsByType = createSelector(
    refundedItems,
    refundedItems => {
        const days = refundedItems.filter(item => item.type === 'day')
        const services = refundedItems.filter(item => item.type === 'service')

        return {
            days,
            services,
        }
    }
)

export const areThereRefundedItems = createSelector(
    refundedItems,
    refundedItems => refundedItems.length > 0
)

export const refundTotal = createSelector(
    refundedItemsByType,
    ({days, services}) =>
        [...days, ...services].reduce((acc, item) => {
            return acc + getPrintedItemTotal(item)
        })
)

export const isLoyaltyAllowed = createSelector(
    isWithoutReservation,
    state => everything(state).is_loyalty_allowed,
    (isWithoutReservation, isAllowed) => {
        return isAllowed && !isWithoutReservation
    }
)

export const validationErrorsPure = state => everything(state).validationErrors

export const validationErrors = createSelector(
    validationErrorsPure,
    days,
    services,
    (validationErrorsPure, days, services) => {
        const fillErrorsObject = (acc, item) => {
            if (!acc[item.id]) {
                acc[item.id] = {}
            }

            return acc
        }

        return {
            ...validationErrorsPure,
            days: days.reduce(fillErrorsObject, {...validationErrorsPure.days}),
            services: services.reduce(fillErrorsObject, {
                ...validationErrorsPure.services,
            }),
        }
    }
)

export const generalValidationErrors = state => validationErrors(state).general

export const hasGeneralError = (state, field) =>
    generalValidationErrors(state)[field]

export const daysValidationErrors = state => validationErrors(state).days

export const servicesValidationErrors = state =>
    validationErrors(state).services

export const initialReservationEnd = state => initialData(state).end

export const isArchived = createSelector(
    initialReservationEnd,
    checkOutTimeObject,
    (initialReservationEnd, checkOutTimeObject) =>
        isReservationArchivedModular({
            end: initialReservationEnd,
            checkOutTimeObject,
        })
)

export const previouslyPayed = createSelector(
    initialData,
    everything,
    (initialData, reservation) => {
        const {reserved_days, additional_services} = initialData
        const {prePayedBreakfast} = reservation
        let result = 0
        reserved_days
            .filter(day => day.type === dayTypes.payed)
            .forEach(day => {
                result += getItemTotal(day)
            })

        additional_services
            .filter(service => service.type === serviceTypes.payed)
            .forEach(service => {
                result += getDiscountedItemTotal(service)
            })
        result += prePayedBreakfast?.reduce((a, b) => a + b.price * b.qty, 0)

        return result
    }
)

const getRoom = day => {
    const {room} = day

    return room
        ? {
            id: room.room_id,
            name: room.name,
        }
        : null
}

const getRoomId = day => {
    const room = getRoom(day)

    if (!room) {
        return null
    }

    return room.id
}

const getMigrations = days => {
    if (days.length === 0) {
        return []
    }

    if (days.length === 1) {
        const [day] = days

        const room = getRoom(day)

        return [
            {
                room,
                startDate: day.date,
                endDate: day.date,
            },
        ]
    }

    const result = []

    let startDate = null

    for (let i = 0; i < days.length - 1; i++) {
        const currentDay = days[i]
        const nextDay = days[i + 1]

        // handling first day
        if (i === 0) {
            startDate = currentDay.date
        }

        const currentRoomId = getRoomId(currentDay)
        const nextRoomId = getRoomId(nextDay)

        if (nextRoomId !== currentRoomId) {
            const currentRoom = getRoom(currentDay)

            const endDate = currentDay.date

            result.push({
                room: currentRoom,
                startDate,
                endDate,
            })

            startDate = nextDay.date
        }

        // handling last day
        if (i + 1 === days.length - 1) {
            const nextRoom = getRoom(nextDay)

            const endDate = nextDay.date

            result.push({
                room: nextRoom,
                startDate,
                endDate,
            })

            break
        }
    }

    return result
}

export const migrations = createSelector(days, days => getMigrations(days))

export const initialDaysUnsorted = state => initialData(state).reserved_days

export const initialDays = createSelector(
    initialDaysUnsorted,
    initialDaysUnsorted => initialDaysUnsorted.sort(sortDays)
)

export const initialServices = state => initialData(state).additional_services

export const initialMigrations = createSelector(initialDays, initialDays =>
    getMigrations(initialDays)
)

export const initialFullMigrationsData = createSelector(
    initialMigrations,
    initialMigrations => {
        const roomNames = initialMigrations.reduce((acc, migration) => {
            const {room} = migration

            if (!room) {
                return acc
            }

            acc[room.id] = room.name

            return acc
        }, {})

        const migrationsMap = initialMigrations.reduce((acc, migration) => {
            const {room, startDate, endDate} = migration

            if (!room) {
                return acc
            }

            if (!acc[room.id]) {
                acc[room.id] = []
            }

            acc[room.id].push({startDate, endDate})

            return acc
        }, {})

        const result = []

        for (let roomId in migrationsMap) {
            result.push({
                room: {
                    id: roomId,
                    name: roomNames[roomId],
                },
                segments: migrationsMap[roomId],
            })
        }

        return result
    }
)

const checkIfDateAttachedToSegment = (date, segment) => {
    const {startDate, endDate} = segment

    const dateMoment = moment(date, 'YYYY-MM-DD')
    const startDateMoment = moment(startDate, 'YYYY-MM-DD')
    const endDateMoment = moment(endDate, 'YYYY-MM-DD')

    if (dateMoment.isBefore(startDateMoment)) {
        return false
    }

    return !dateMoment.isAfter(endDateMoment);


}

export const emptyDateSegmentsBetweenInitialMigrations = createSelector(
    daysDates,
    initialFullMigrationsData,
    (daysDates, initialFullMigrationsData) => {
        return initialFullMigrationsData.map(migrationData => {
            const {room, segments} = migrationData

            const emptySegments = []

            let startDate = null
            let endDate = null

            for (let date of daysDates) {
                const isOutside = segments.every(segment => {
                    return !checkIfDateAttachedToSegment(date, segment)
                })

                if (!isOutside) {
                    if (!startDate) continue

                    emptySegments.push({
                        startDate,
                        endDate,
                    })

                    startDate = null
                    endDate = null

                    continue
                }

                if (!startDate) {
                    startDate = date
                }

                endDate = date
            }

            if (startDate && endDate) {
                emptySegments.push({
                    startDate,
                    endDate,
                })
            }

            return {
                room,
                emptySegments,
            }
        })
    }
)

export const wereNewDaysAdded = createSelector(
    days,
    initialDays,
    (days, initialDays) => {
        return days.length > initialDays.length
    }
)

export const wasReservationExtended = createSelector(
    isReady,
    wereNewDaysAdded,
    (isReady, wereNewDaysAdded) => {
        return isReady && wereNewDaysAdded
    }
)

export const discountedItems = createSelector(
    discountedDays,
    discountedServices,
    (days, services) => ({days, services})
)

export const discountedItemsIds = createSelector(
    discountedDaysIds,
    discountedServicesIds,
    (days, services) => ({days, services})
)

export const isGettingBonusesFromPaidItems = state =>
    everything(state).isGettingBonusesFromPaidItems

export const hasReservationStartNotCome = createSelector(
    currentDateSelectors.today,
    reservationStart,
    (currentDate, start) => {
        return moment(currentDate).isBefore(start)
    }
)

export const isStartOkForManualLoyaltyApply = createSelector(
    hasReservationStartNotCome,
    purchasedItemsWithAllowableLoyalty,
    (hasStartNotCome, {days, services}) => {
        const hasStartCome = !hasStartNotCome

        if (hasStartCome) return true

        const hasBank =
            days.some(day => day.payment_type === paymentTypes.bank) ||
            services.some(service => service.payment_type === paymentTypes.bank)
        return !hasBank;


    }
)

export const daysBeforeStart = createSelector(
    currentDateSelectors.today,
    reservationStart,
    (currentDate, start) => {
        return moment(start).diff(currentDate, 'days')
    }
)

export const isStartOkForAutomaticLoyaltyApply = createSelector(
    hasReservationStartNotCome,
    purchasedItemsWithAllowableLoyalty,
    daysBeforeStart,
    (hasStartNotCome, {days, services}, daysBeforeStart) => {
        const hasStartCome = !hasStartNotCome

        if (hasStartCome) return true

        const hasBank =
            days.some(day => day.payment_type === paymentTypes.bank) ||
            services.some(service => service.payment_type === paymentTypes.bank)

        if (hasBank) return false
        return daysBeforeStart <= 2
    }
)

export const isReservationStartToday = createSelector(
    currentDateSelectors.today,
    reservationStart,
    (currentDate, start) => {
        return moment(currentDate).isSame(start) || moment(start).isBefore(currentDate)
    }
)

export const hasReservationStartPassed = createSelector(
    currentDateSelectors.today,
    reservationStart,
    (currentDate, start) => {
        return moment(currentDate).isAfter(start)
    }
)

export const washingServices = createSelector(services, services =>
    services.filter(({service}) => service === 'Стирка')
)

export const initialWashingServices = createSelector(
    initialServices,
    services => services.filter(({service}) => service === 'Стирка')
)

export const newWashingServices = createSelector(
    washingServices,
    washingServices =>
        washingServices.filter(service => service.type === serviceTypes.new)
)

export const newWashingsCount = createSelector(
    newWashingServices,
    newWashingServices =>
        newWashingServices.reduce((acc, service) => {
            return acc + Number(service.quantity)
        }, 0)
)

export const roomWithWashingService = createSelector(
    days,
    services,
    (days, services) => {
        const washingService = services.find(({service}) => service === 'Стирка')
        if (!washingService) return null

        const {date} = washingService

        const day = days.find(day => day.date === date)
        if (!day) return null

        const roomId = day?.room?.room_id
        if (!roomId) return null

        return roomId
    }
)

export const hasValidationErrors = state =>
    everything(state).hasValidationErrors

export const hasPaymentValidationErrors = state =>
    everything(state).hasPaymentValidationErrors

export const cards = state => everything(state).hasCard

export const rooms = state => everything(state).rooms

export const isPreReady = state => everything(state).isPreReady

export const hasCheckedInRoom = state => everything(state).newRoomID || everything(state).lastRoomID

export const fromOtherHotel = createSelector(
    lcode, currentLcode,
    (lcode, currentLcode) => lcode?.lcode !== currentLcode
)

export const groupReservation = state => everything(state).groupReservation
