import React from 'react'
import {callModal, modalTemplates} from '../../../../dynamic-modal'
import {AboutCancel, QuestionIcon} from "./QuestionIcon";
import styled from "styled-components";
import {BunkBedIconTip} from "../../../../common/IconsWithTooltips";
import screenShot from "./check.png"

const Icon = styled.div`
    background-color: #f0ad4e;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: inline-flex;
    vertical-align: ${props => props.top ? 'text-top' : 'baseline'};
    margin: 0 5px;

    & > span {
        margin: auto;

        svg {
            vertical-align: middle;
            transform: scale(1.5);
        }
    }
`

const ScreenShot = styled.img`
    width: 100%;
    margin-bottom: 10px;
`

export const SecondTierInfoModal = () => (
    <div>
        <strong>Бронирования, которым требуется застил второго яруса, отображаются в заездах сегодня с такой
            иконкой <Icon top>
                <BunkBedIconTip/>
            </Icon></strong>
        <p>Для подготовки номера к заезду гостей по таким бронированиям необходимо выбрать номер для заселения и
            назначить уборку второго яруса в этот номер.
        </p>


        <ScreenShot src={screenShot} alt=""/>
        <p>
            Горничные увидят эту информацию в своем задании на уборку в приложении и подготовят спальное место до
            заселения гостей, чтобы гостям не пришлось застилать второй ярус самостоятельно.

        </p>

        <p>
            Номера с застланным вторым ярусом отображаются в шахматке с соответствующей иконкой рядом с названием
            категории <Icon>
            <BunkBedIconTip/>
        </Icon>, что позволит легко увидеть номер с застланным вторым ярусом в шахматке, и заселить в
            него гостей, которым требуется застил второго яруса.
        </p>
    </div>
)


export const SecondTierInfoHoverIcon = () => {
    const showModal = () => (
        callModal(
            modalTemplates.alert({
                title: 'О подготовке второго яруса к заезду',
                text: <SecondTierInfoModal/>,
            })
        )
    )

    return (
        <AboutCancel onClick={showModal}>
            Подготовка второго яруса
            <QuestionIcon/>
        </AboutCancel>
    )
}
