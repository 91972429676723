export const LOAD_DATA_REQUEST = 'ghotel/today-check-ins/LOAD_DATA_REQUEST'
export const LOAD_DATA_SUCCESS = 'ghotel/today-check-ins/LOAD_DATA_SUCCESS'
export const LOAD_DATA_FAILURE = 'ghotel/today-check-ins/LOAD_DATA_FAILURE'

export const LOAD_CHECK_INS_WITH_CLEANING_SECOND_TIER = 'ghotel/today-check-ins/LOAD_CHECK_INS_WITH_CLEANING_SECOND_TIER'

export const UPDATE_DATA_REQUEST = 'ghotel/today-check-ins/UPDATE_DATA_REQUEST'
export const UPDATE_DATA_SUCCESS = 'ghotel/today-check-ins/UPDATE_DATA_SUCCESS'
export const UPDATE_DATA_FAILURE = 'ghotel/today-check-ins/UPDATE_DATA_FAILURE'

export const CANCEL_RESERVATION_REQUEST =
  'ghotel/today-check-ins/CANCEL_RESERVATION_REQUEST'
export const CANCEL_RESERVATION_SUCCESS =
  'ghotel/today-check-ins/CANCEL_RESERVATION_SUCCESS'
export const CANCEL_RESERVATION_FAILURE =
  'ghotel/today-check-ins/CANCEL_RESERVATION_FAILURE'
export const CANCEL_RESERVATION_CANCELED =
  'ghotel/today-check-ins/CANCEL_RESERVATION_CANCELED'

export const RESET = 'ghotel/today-check-ins/RESET'
