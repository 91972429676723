import React from "react";
import styled from 'styled-components'
import {useSelector} from "react-redux";
import {stickersActions, stickersSelectors} from "../reducer";
import {Item} from "./Item";
import {FaPlus} from "react-icons/fa";
import {useActions} from "../../common/hooks/use-actions";
import {archive, relevant} from "../reducer/selectors";
import {createStickerModal} from "./AddStickerModal";
import {sessionSelectors} from "../../../Modules/session";
import {changeStatusConfirmation} from "./ChangeStatusConfirmation";

const Block = styled.div`
    position: relative;
    height: 100%;

    & > div {
        bottom: 0;
        left: 0;
        overflow-y: hidden;
        position: absolute;
        right: 0;
        top: 0;
        white-space: nowrap;
        display: flex;
        gap: 15px;
    }
`

const Column = styled.div`
    width: 50%;
    box-sizing: border-box;
    height: 100%;
    vertical-align: top;
    background-color: #fafafa;

    display: flex;
    flex-direction: column;

    h4 {
        background: #dde2ec;
        padding: 10px;
        margin: 0;
    }
`
const Overflow = styled.div`
    overflow: auto;
    height: 100%;
    position: relative;
`

const AddStickerBtn = styled.div`
    padding: 20px 25px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
    cursor: pointer;
`

const Num = styled.span`
    background-color: #bfc8db;
    border-radius: 2px;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 8px;
    margin-left: 10px;`

export const Content = () => {
    const archiveStickers = useSelector(stickersSelectors.archiveStickers)
    const activeStickers = useSelector(stickersSelectors.activeStickers)
    const adminInfo = useSelector(sessionSelectors.adminInfo)
    const lcode = useSelector(sessionSelectors.lcode)
    const {updateSticker: upd, createSticker} = useActions(stickersActions)

    const columns = [
        {
            name: "Активные",
            items: activeStickers,
            hasControl: true,
            setStatus: archive
        },
        {
            name: "Архивные",
            items: archiveStickers,
            setStatus: relevant
        }
    ]

    const changeStatus = ({id, category}) => {
        changeStatusConfirmation(category).then((res) => {
            if (res) upd({id, category})
        })
    }

    const addSticker = async () => {
        const content = await createStickerModal()
        if (content)
            await createSticker({admin: adminInfo, ...content, lcodes: [lcode]})
    }

    const editSticker = async ({id, ...data}) => {
        const content = await createStickerModal({id, ...data})
        if (content)
            await upd({id, admin: adminInfo, ...content})
    }

    return (
        <Block>
            <div>
                {columns.map(({name, items, hasControl, setStatus}) =>
                    <Column key={name}>
                        <h4>{name} <Num>{items.length}</Num></h4>
                        <Overflow>
                            {items.map((it) => <Item
                                item={it} key={it.id}
                                changeStatus={(id) => changeStatus({id, category: setStatus})}
                                editSticker={(item) => editSticker({id: it.id, ...item})}
                            />)}
                        </Overflow>
                        {hasControl && <AddStickerBtn onClick={addSticker}>
                            <FaPlus/> Добавить
                        </AddStickerBtn>}
                    </Column>
                )}
            </div>
        </Block>
    )
}
