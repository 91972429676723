import * as types from './types'

const initialState = {
    wubook: [],
    wubookCount: 0,
    prepayeds: [],
    cancelingPKs: [],
    isUpdating: false,
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    checkInsWithCleaningSecondTier: []
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_DATA_REQUEST:
            return {
                ...state,
                wubook: [],
                wubookCount: 0,
                prepayeds: [],
                isLoading: true,
            }

        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                wubook: payload.wubook,
                wubookCount: payload.wubookCount,
                prepayeds: payload.prepayeds,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.UPDATE_DATA_REQUEST:
            return {
                ...state,
                isUpdating: true,
            }

        case types.UPDATE_DATA_SUCCESS:
        case types.UPDATE_DATA_FAILURE:
            return {
                ...state,
                isUpdating: false,
            }

        case types.CANCEL_RESERVATION_REQUEST:
            return {
                ...state,
                cancelingPKs: state.cancelingPKs.concat(payload.pk),
            }

        case types.CANCEL_RESERVATION_SUCCESS:
        case types.CANCEL_RESERVATION_FAILURE:
        case types.CANCEL_RESERVATION_CANCELED:
            return {
                ...state,
                cancelingPKs: state.cancelingPKs.filter(pk => pk !== payload.pk),
            }

        case  types.LOAD_CHECK_INS_WITH_CLEANING_SECOND_TIER:
            return {
                ...state,
                ...payload
            }

        case types.RESET:
            return initialState

        default:
            return state
    }
}
