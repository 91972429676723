import React from 'react'

export const CalendarSvg = () => <svg width="24" height="24" viewBox="0 0 28 28" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.3749 21.9356H4.93365C3.8189 21.9356 2.9124 21.0291 2.9124 19.9143V7.0177C2.9124 5.90295 3.8189 4.99645 4.93365 4.99645H6.83415V5.47245C6.83415 5.90732 7.18678 6.25995 7.62165 6.25995C8.05653 6.25995 8.40915 5.90732 8.40915 5.47245V4.99645H17.8277V5.47245C17.8277 5.90732 18.1803 6.25995 18.6152 6.25995C19.05 6.25995 19.4027 5.90732 19.4027 5.47245V4.99645H21.2962C22.41 4.99645 23.3174 5.90295 23.3174 7.0177V12.6439C23.3174 13.0788 23.67 13.4314 24.1049 13.4314C24.5398 13.4314 24.8924 13.0788 24.8924 12.6439V7.0177C24.8924 5.03495 23.2789 3.42145 21.2962 3.42145H19.4027V3.01895C19.4027 2.58407 19.05 2.23145 18.6152 2.23145C18.1803 2.23145 17.8277 2.58407 17.8277 3.01895V3.42145H8.40915V3.01895C8.40915 2.58407 8.05653 2.23145 7.62165 2.23145C7.18678 2.23145 6.83415 2.58407 6.83415 3.01895V3.42145H4.93365C2.9509 3.42145 1.3374 5.03495 1.3374 7.0177V19.9152C1.3374 21.8988 2.9509 23.5114 4.93365 23.5114H14.3749C14.8098 23.5114 15.1624 23.1588 15.1624 22.7239C15.1624 22.2891 14.8098 21.9356 14.3749 21.9356Z"
        fill="white"/>
    <path
        d="M7.45722 10.7139C6.95497 10.7139 6.5481 11.1207 6.5481 11.623C6.5481 12.1252 6.95497 12.5321 7.45722 12.5321C7.95947 12.5321 8.36635 12.1252 8.36635 11.623C8.36635 11.1207 7.95947 10.7139 7.45722 10.7139Z"
        fill="white"/>
    <path
        d="M13.1174 10.7139C12.6151 10.7139 12.2083 11.1207 12.2083 11.623C12.2083 12.1252 12.6151 12.5321 13.1174 12.5321C13.6196 12.5321 14.0265 12.1252 14.0265 11.623C14.0265 11.1207 13.6188 10.7139 13.1174 10.7139Z"
        fill="white"/>
    <path
        d="M17.8682 11.623C17.8682 12.1252 18.275 12.5321 18.7773 12.5321C19.2795 12.5321 19.6864 12.1252 19.6864 11.623C19.6864 11.1207 19.2795 10.7139 18.7773 10.7139C18.275 10.7139 17.8682 11.1207 17.8682 11.623Z"
        fill="white"/>
    <path
        d="M7.45722 15.6675C6.95497 15.6675 6.5481 16.0744 6.5481 16.5766C6.5481 17.0789 6.95497 17.4857 7.45722 17.4857C7.95947 17.4857 8.36635 17.0789 8.36635 16.5766C8.36635 16.0744 7.95947 15.6675 7.45722 15.6675Z"
        fill="white"/>
    <path
        d="M13.1174 15.6675C12.6151 15.6675 12.2083 16.0744 12.2083 16.5766C12.2083 17.0789 12.6151 17.4857 13.1174 17.4857C13.6196 17.4857 14.0265 17.0789 14.0265 16.5766C14.0265 16.0744 13.6188 15.6675 13.1174 15.6675Z"
        fill="white"/>
    <path
        d="M21.8189 14.5825C18.7345 14.5825 16.2241 17.092 16.2241 20.1764C16.2241 23.2599 18.7336 25.7703 21.8189 25.7703C24.9041 25.7703 27.4136 23.2608 27.4136 20.1764C27.4127 17.0911 24.9032 14.5825 21.8189 14.5825ZM21.8189 24.1935C19.6034 24.1935 17.8 22.391 17.8 20.1755C17.8 17.96 19.6034 16.1566 21.8189 16.1566C24.0344 16.1566 25.8377 17.96 25.8377 20.1755C25.8377 22.391 24.0344 24.1935 21.8189 24.1935Z"
        fill="white"/>
    <path
        d="M22.9593 18.7434L21.2338 20.1338L20.8042 19.5852C20.5347 19.243 20.042 19.1835 19.6982 19.4522C19.356 19.7208 19.2965 20.2152 19.5652 20.5582L20.4874 21.7342C20.6169 21.8995 20.8077 22.0072 21.0168 22.0308C21.0465 22.0343 21.0763 22.036 21.1069 22.036C21.2854 22.036 21.4604 21.9748 21.6004 21.8619L23.9472 19.9719C24.2867 19.698 24.3392 19.2028 24.067 18.8642C23.794 18.5255 23.2979 18.4695 22.9593 18.7434Z"
        fill="white"/>
</svg>

