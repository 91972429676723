import React from 'react'

import {
    GroupIcon as PureGroupIcon,
    NoteIcon as PureNoteIcon,
    PayedInAdvanceIcon as PurePayedInAdvanceIcon,
    PrepayedIcon as PurePrepayedIcon,
} from './Icons'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FaTimes} from 'react-icons/fa'
import {BunkBedIconLegend} from "../MainPage/components/grid/components/BunkBedIcon";

export const IconWrapper = ({tip, children, id, html, delayHide = 400, ...rest}) => {
    return (<OverlayTrigger
        placement="top"
        delayShow={250}
        delayHide={delayHide}
        overlay={html
            ? <Tooltip html={true} id={id} {...rest}>
                <div dangerouslySetInnerHTML={{__html: html}}/>
            </Tooltip>
            : <Tooltip id={id} {...rest}>{tip}</Tooltip>}
    >
        {children}
    </OverlayTrigger>)
}

export const GroupIcon = ({style}) => (
    <IconWrapper tip="Групповая бронь" id="GroupIcon">
    <span>
      <PureGroupIcon style={style}/>
    </span>
    </IconWrapper>)

export const PrepayedIcon = ({style}) => (
    <IconWrapper tip="Имеются предоплаты" id="PrepayedIcon">
    <span>
      <PurePrepayedIcon style={style}/>
    </span>
    </IconWrapper>
)

export const NoteIcon = ({style}) => (
    <IconWrapper tip="Имеются заметки" id="NoteIcon">
    <span>
      <PureNoteIcon style={style}/>
    </span>
    </IconWrapper>
)

export const PayedInAdvanceIcon = ({style}) => (
    <IconWrapper tip="Бронь заранее оплачена" id="PayedInAdvanceIcon">
    <span>
      <PurePayedInAdvanceIcon style={style}/>
    </span>
    </IconWrapper>)

export const CancelReservationIcon = () => (
    <IconWrapper tip="Отменить" id="CancelReservationIcon">
    <span>
      <FaTimes style={{margin: "5px 1px 1px", transform: "scale(1.5)"}}/>
    </span>
    </IconWrapper>)


export const BunkBedIconTip = ({checked}) => (
    <IconWrapper tip={checked ? "Застил назначен" : "Пометить застил назначенным"} id="BunkBedIconTip">
    <span>
     <BunkBedIconLegend style={{verticalAlign: 'bottom'}}/>
    </span>
    </IconWrapper>)
