import React from 'react'
import styled from 'styled-components'
import {Tasks} from './components/left-side/tasks'
import {useSelector} from 'react-redux'
import {settingsSelectors} from '../../Modules/settings'
import {FreeWashingMachines} from './components/left-side/free-washing-machines'
import {BreakfastsToday} from './components/left-side/breakfasts-today'
import {MaidsCheckOutMain} from './components/left-side/maids-info/MaidsCheckOutMain'
import {FillCheckListAlert} from "../Shifts/FillCheckListAlert";
import {FreeRooms} from "./components/left-side/free-rooms/FreeRooms";
import {NightCheckins} from "./components/left-side/check-ins/NightCheckin";
import {TodayCheckIns} from "./components/left-side/check-ins/TodayCheckIns";
import {sessionSelectors} from "../../Modules/session";
import {newsSelectors} from "../SpecialButtons/News/reducer";
import {BreakfastWarnings} from "./components/left-side/warnings/BreakfastWarnings";
import {ReservationWarnings} from "./components/left-side/warnings/ReservationWarnings";
import {TodayNoShow} from "./components/left-side/today-no-show/TodayNoShow";
import {
    ReservationsWaitingForBonuses
} from "./components/left-side/reservations-waiting-for-bonuses/ReservationsWaitingForBonuses";
import {News} from "./components/left-side/news";
import {Stickers} from "./components/left-side/stickers";
import {FastBooking, FastBookingButton} from "./components/left-side/fast-booking-booking";

const LeftSideBlock = styled.div`
    width: 500px;
    flex-shrink: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
    }
`

const LeftSide = () => {
    const isChecklistEnabled = useSelector(settingsSelectors.isChecklistEnabled)

    const isLoyaltyEnabled = useSelector(settingsSelectors.isLoyaltyEnabled)
    const isTodoListEnabled = useSelector(settingsSelectors.isTodoListEnabled)
    const isAuthorized = useSelector(sessionSelectors.isAuthorized)
    const isWashingOperationsOn = useSelector(
        settingsSelectors.isWashingOperationsOn
    )
    const hasNewNews = useSelector(newsSelectors.hasNewNews)

    return (
        <LeftSideBlock>
            {isAuthorized && isChecklistEnabled && <FillCheckListAlert/>}
            <FastBooking/>
            <Stickers/>
            {hasNewNews && <News/>}
            <MaidsCheckOutMain/>
            <ReservationWarnings/>
            <BreakfastWarnings/>
            <BreakfastsToday/>
            {isLoyaltyEnabled && <ReservationsWaitingForBonuses/>}
            {isTodoListEnabled && <Tasks/>}
            {isWashingOperationsOn && <FreeWashingMachines/>}
            <FreeRooms/>
            <NightCheckins/>
            <TodayCheckIns/>
            <TodayNoShow/>
        </LeftSideBlock>
    )
}

export default LeftSide
