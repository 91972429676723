import React from 'react'
import moment from 'moment'
import {useSelector} from "react-redux";
import {todayCheckInsSelectors} from "../../../reducers/today-check-ins";
import {CheckIns} from "./CheckIns";


export const NightCheckins = () => {
    const nightCheckins = useSelector(todayCheckInsSelectors.nightCheckins)
    const count = nightCheckins.length

    if (!count) return <div/>

    return <CheckIns count={count} wubook={nightCheckins} header={<>Ожидаемые заезды{' '}
        {moment()
            .subtract(1, 'days')
            .format('DD MMMM')}</>}/>
}

