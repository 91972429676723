import {createSelector} from 'reselect'

export const relevant = "relevant"
export const archive = "archive"

export const everything = state => state.stickers

export const items = state => everything(state).items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

export const isHidden = state => everything(state).hidden

export const activeStickers = createSelector(items, items =>
    items?.filter(({category}) => category === relevant)
)

export const countActiveStickers = createSelector(activeStickers, items => items.length)

export const archiveStickers = createSelector(items, items =>
    items?.filter(({category}) => category === archive)
)

export const lastFiveStickers = createSelector(activeStickers, items =>
    items.slice(0, 5)
)
