export const upgradeService = 'Улучшение категории номера проживания'
export const upgradeService_legacy = 'Улучшение'
export const earlyCheckInService_legacy = 'Ранний заезд'
export const earlyCheckInService = 'Ранний заезд по проживанию'
export const lateCheckOutService_legacy = 'Поздний выезд'
export const lateCheckOutService = 'Поздний выезд по проживанию'
export const livingServiceName = 'Проживание'
export const breakfastServiceName = 'Завтрак'
export const parkingServiceName = 'Парковка'
export const livingWithAFine = 'Проживание со штрафом'


