import React from 'react'
import {ControlLabel, FormGroup} from 'react-bootstrap'
import {BunkBedIconLegend} from "../../MainPage/components/grid/components/BunkBedIcon";
import Select from 'react-select';

export const RoomSelect = ({
                               name,
                               value,
                               values,
                               onChange,
                               disabled,
                               validationState,
                               style
                           }) => {

    const formatOptionLabel = ({label, secondTier}) => (
        <div style={{display: "flex", gap: '5px'}}>

            <div>{label}</div>
            {secondTier && <BunkBedIconLegend color='#555'/>}
        </div>
    );

    return (
        <>
            <FormGroup validationState={validationState}>
                <ControlLabel>Комната {value}</ControlLabel>
                <Select
                    name={name}
                    placeholder="Выберите комнату"
                    options={values}
                    value={values.find(it => it.value === value)}
                    closeMenuOnSelect={true}
                    formatOptionLabel={formatOptionLabel}
                    invalid={!validationState}
                    disabled={disabled}
                    onChange={({value}) => {
                        onChange(value)
                    }}
                    styles={{
                        placeholder: (base) => ({...base, color: '#555', fontSize: '12px'}),
                        control: (base) => ({
                            ...base,
                            ...style,
                            borderRadius: '2px',
                            minHeight: '30px'
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            color: '#555',
                            fontSize: '12px',

                        }),
                        dropdownIndicator: (base) => ({...base, padding: '2px'}),
                    }}
                />
            </FormGroup>
        </>
    )


}
