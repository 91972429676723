import { combineReducers } from 'redux'
import reservation from './reservation/ReservationModule'
import { allPrepayedsReducer } from '../Components/AllPrepayeds/reducer'
import settings from './settings/reducer'
import { gridReducer } from '../Components/MainPage/reducers/grid'
import { tasksReducer } from '../Components/MainPage/reducers/tasks'
import { todayCheckInsReducer } from '../Components/MainPage/reducers/today-check-ins'
import { todayNoShowReducer } from '../Components/MainPage/reducers/today-no-show'
import { freeRoomsReducer } from '../Components/MainPage/reducers/free-rooms'
import { checksReducer } from '../Components/DayTotals/features/MainPage/reducers/checks'
import { refundsReducer } from '../Components/DayTotals/features/MainPage/reducers/refunds'
import { prepayedsReducer } from './prepayeds'
import { loyaltyClientReducer } from './loyalty-client'
import { loyaltyPurchaseReducer } from './loyalty-purchase'
import { notesReducer } from './notes'
import { allNotesReducer } from './all-notes'
import { hotelsReducer } from './hotels'
import { modalsReducer } from './modals'
import { payTimerReducer } from './pay-timer'
import { filesReducer } from '../Components/Files'
import { currentAdminSellsReducer } from '../Components/CurrentAdminSells'
import { blacklistReducer } from '../Components/Blacklist/reducers/blacklist'
import { specialServiceTypesReducer } from './special-service-types'
import { currentDateReducer } from './current-date'
import { reservationsWaitingForBonusesReducer } from '../Components/MainPage/reducers/reservations-waiting-for-bonuses'
import { washingReducer } from './washing'
import { roomsReducer } from './rooms'
import { breakfastsReducer } from './breakfasts'
import { telephonyReducer } from '../Components/Telephony/reducer/telephony'
import { sessionReducer } from './session';
import { registrationFormsReducer } from './registration-forms'
import { roomsPhysicalReducer } from './roomsPhysical'
import { canceledWarningsReducer } from '../Components/MainPage/reducers/canceled-warnings'
import { lateCheckoutsReducer } from './late-checkouts'
import { breakfastsBookedReducer } from './breakfastsBooked'
import { maidsReducer } from './maids'
import { newsReducer } from '../Components/SpecialButtons/News/reducer'
import { wubookReservationsReducer } from '../Components/WubookList/reducer'
import { otasListReducer } from './otas-list'
import {bedclothesChangeReducer} from "./bedclothesChange";
import {breakfastsRestrictionsReducer} from "./breakfastRestriictions";
import {shiftsReducer} from "./shifts";
import {commentsAboutClientReducer} from "./comments-about-client";
import {depositsReducer} from "./deposits";
import {stickersReducer} from "../Components/Stickers/reducer";

export default combineReducers({
  grid: gridReducer,
  tasks: tasksReducer,
  reservation,
  allPrepayeds: allPrepayedsReducer,
  bedclothesChange: bedclothesChangeReducer,
  session: sessionReducer,
  settings,
  checks: checksReducer,
  refunds: refundsReducer,
  prepayeds: prepayedsReducer,
  notes: notesReducer,
  todayCheckIns: todayCheckInsReducer,
  todayNoShow: todayNoShowReducer,
  freeRooms: freeRoomsReducer,
  loyaltyClient: loyaltyClientReducer,
  loyaltyPurchase: loyaltyPurchaseReducer,
  allNotes: allNotesReducer,
  hotels: hotelsReducer,
  modals: modalsReducer,
  payTimer: payTimerReducer,
  files: filesReducer,
  specialServiceTypes: specialServiceTypesReducer,
  breakfasts: breakfastsReducer,
  currentAdminSells: currentAdminSellsReducer,
  blacklist: blacklistReducer,
  currentDate: currentDateReducer,
  reservationsWaitingForBonuses: reservationsWaitingForBonusesReducer,
  washing: washingReducer,
  rooms: roomsReducer,
  telephony: telephonyReducer,
  breakfastsBooked: breakfastsBookedReducer,
  registrationForms: registrationFormsReducer,
  roomsPhysical: roomsPhysicalReducer,
  canceledWarnings: canceledWarningsReducer,
  lateCheckouts: lateCheckoutsReducer,
  maids: maidsReducer,
  news: newsReducer,
  wubookReservations: wubookReservationsReducer,
  otasList: otasListReducer,
  breakfastsRestrictions: breakfastsRestrictionsReducer,
  shifts: shiftsReducer,
  commentsAboutClient: commentsAboutClientReducer,
  deposits: depositsReducer,
  stickers: stickersReducer
})
