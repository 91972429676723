import React from 'react'
import {useSelector} from "react-redux";
import {newsSelectors} from "../../../../SpecialButtons/News/reducer";
import {Template} from "../common/Template";
import {Item} from "./Item";
import {DarkButton} from "../common/DarkButton";
import history from "../../../../../Modules/helpers/history";

export const News = () => {
    const news = useSelector(newsSelectors.newNewsList)
    const goTo = () => {
        history.push('/news')
    }

    return (
        <Template header='Непрочитанные новости'
                  headerControl={<DarkButton onClick={goTo}><ListIcon/>Все новости</DarkButton>}>
            {news.map(it => <Item key={it.id} item={it} short={true}/>)}
        </Template>
    )
}


const ListIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2 2.66699V12.0003C2 12.7367 2.59695 13.3337 3.33333 13.3337H11.3333H12.6667C13.403 13.3337 14 12.7367 14 12.0003V5.33366H11.3333"
        stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 2.66699H11.3333V12.0003C11.3333 12.7367 11.9303 13.3337 12.6667 13.3337V13.3337" stroke="white"
          strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.6665 5.33301L4.6665 5.33301" stroke="white" strokeWidth="1.33333" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M8.66667 8L6 8" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
