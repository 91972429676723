import * as types from './types'
import {room_statuses} from "../../Components/MainPage/components/grid/room-statuses/room-statuses";

const initialState = {
    items: [],
    history: [],
    isLoading: false,
    isLoaded: false,
    isFailed: false,
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_REQUEST:
            return {
                ...initialState,
                isLoading: true,
            }

        case types.LOAD_SUCCESS:
            return {
                ...state,
                items: payload.items,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.UPDATE_STATUS_REQUEST:
            const {data} = payload
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                items: state.items.map((it) => {
                    const {
                        status,
                        cleaning_second_tier,
                        repair_schedule,
                        busy_with_employee_dates
                    } = data.find(({room_id}) => it.room_id === room_id)
                    if (it.status !== status) {
                        it.status = status
                    }
                    if (it.cleaning_second_tier !== cleaning_second_tier) {
                        it.cleaning_second_tier = cleaning_second_tier
                    }
                    if (busy_with_employee_dates.length || busy_with_employee_dates.length !== it.busy_with_employee_dates) {
                        it.busy_with_employee_dates = busy_with_employee_dates
                    }
                    if (repair_schedule.length || repair_schedule.length !== it.repair_schedule) {
                        it.repair_schedule = repair_schedule
                    }
                    return it
                })
            }
        case types.UPDATE_ROOM_STATUS_REQUEST:
            const {room_id, status, dates, cleaning_second_tier} = payload
            return {
                ...state,
                isLoading: false,
                isFailed: false,
                items: state.items.map((it) => {
                    if (it.room_id === room_id) {
                        it.status = status
                        it.cleaning_second_tier = cleaning_second_tier
                        if (status === room_statuses.repair.title) {
                            it.repair_schedule = dates
                        } else if (status === room_statuses.busy_with_employee.title) {
                            it.busy_with_employee_dates = dates
                        }
                    }
                    return it
                })
            }

        case types.GET_ROOM_CHANGES_HISTORY:
            return {
                ...state,
                ...payload,
            }

        case types.RESET:
            return initialState

        default:
            return state
    }
}
