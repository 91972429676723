import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import {Button, Glyphicon} from 'react-bootstrap'
import {
    ModalButton,
    ModalButtonWithLoading,
    ModalContent,
    ModalControls,
    ModalHR,
    ModalTitle,
} from '../../../../common/ModalParts'
import {createTaskCall, getAdminsCall} from '../../../../../Modules/api'
import {Select} from '../../../../form-stuff/Select'
import {callModal} from '../../../../dynamic-modal'
import {useSelector} from 'react-redux'
import {tasksActions, tasksSelectors} from '../../../reducers/tasks'
import {useActions} from '../../../../common/hooks/use-actions'
import {getStyle} from '../../../../dynamic-modal/helpers/get-style'
import {useSource} from '../../../../common/hooks/use-source'
import {sessionSelectors} from '../../../../../Modules/session'
import {useFormik} from 'formik'
import {formValidators, validateForm,} from '../../../../../Modules/helpers/form-validation'
import {Textarea} from '../../../../form-stuff/Textarea'
import {Notifications} from '../../../../../Modules/Notifications'
import history from '../../../../../Modules/helpers/history'
import {Template} from "../common/Template";

export const Tasks = () => {
    return (
        <Template header='Задачи для вас'>
            <ContentBlock>
                <Content/>
            </ContentBlock>
            <Actions/>
        </Template>
    )
}

const ContentBlock = styled.div`
    margin-bottom: 8px;
`

const Content = () => {
    const tasks = useSelector(tasksSelectors.items)
    const isLoading = useSelector(tasksSelectors.isLoading)
    const isLoaded = useSelector(tasksSelectors.isLoaded)
    const isFailed = useSelector(tasksSelectors.isFailed)

    if (isLoading) return <div>Загрузка..</div>
    if (isFailed) return <div>Ошибка при загрузке данных</div>
    if (!isLoaded) return <div>Данные не загружены</div>
    if (tasks.length === 0) return <div>Нет активных задач</div>

    return <TaskList/>
}

const TaskList = () => {
    const tasks = useSelector(tasksSelectors.items)

    return (
        <div>
            <TasksHint>
                Чтобы отметить задачу как выполненную, нажмите на крестик
            </TasksHint>
            {tasks.map(task => (
                <Task key={task.pk} data={task}/>
            ))}
        </div>
    )
}

const Task = ({data}) => {
    const {task, author} = data

    const [completing, setCompleting] = useState(false)
    const {completeTask} = useActions(tasksActions)

    const complete = async () => {
        if (completing) return
        setCompleting(true)
        await completeTask(data)
        setCompleting(false)
    }

    return (
        <StyledTaskBlock>
      <span>
        {task} (от {author})
      </span>
            <StyledTaskGlyph
                glyph={completing ? 'time' : 'remove'}
                onClick={complete}
            />
        </StyledTaskBlock>
    )
}

const StyledTaskBlock = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 6px 6px 6px 12px;
    border-radius: 2px;
    background-color: #f7f7f7;
    vertical-align: middle;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.27);
`

const StyledTaskGlyph = styled(Glyphicon)`
    color: #444;
    padding: 3px;
    margin-left: 6px;
    align-self: flex-start;
    transform: translateY(-1px);
    cursor: ${props => (props.isFetching ? 'auto' : 'pointer')};
`

const TasksHint = styled.p`
    font-size: 14px;
    margin-top: 0;
    color: #da4e4e;
`

const Actions = () => {
    return <AddTaskButton/>
}

const AddTaskButton = () => {
    const openModal = () => {
        callModal(AddTaskModal)
    }

    return (
        <Button
            bsSize="small"
            bsStyle="success"
            onClick={openModal}
            style={{marginTop: 5, width: '100%'}}
        >
            <Glyphicon glyph="plus" style={{marginRight: 5}}/> Добавить задачу
        </Button>
    )
}

const AddTaskModal = ({isOpen, close}) => {
    return (
        <Modal isOpen={isOpen} style={getStyle({width: 320})}>
            <ModalTitle>Новая задача</ModalTitle>
            <ModalHR/>
            <AddTaskModalContent close={close}/>
        </Modal>
    )
}

const types = [
    'Себе',
    'Другому администратору',
    'Тех. отдел',
    'Прачечная',
    'Админ. директор',
    'Отд. бронирований',
    'Сантехника',
]

const typesMap = types.reduce((acc, name) => {
    acc[name] = true
    return acc
}, {})

const AddTaskModalContent = ({close}) => {
    const currentAdminName = useSelector(sessionSelectors.adminName)
    const currentAdminId = useSelector(sessionSelectors.adminId)
    const {data: admins, loading, loaded, failed} = useSource(getAdminsCall)

    const {
        values,
        errors,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = useFormik({
        initialValues: {
            type: '',
            adminId: '',
            task: '',
        },
        onSubmit: async (values, actions) => {
            const {errors, hasErrors} = validateForm(values, {
                type: [formValidators.required],
                adminId: [formValidators.required],
                task: [formValidators.required],
            })

            if (hasErrors) {
                actions.setErrors(errors)
                return
            }

            const {adminId, task} = values

            try {
                await createTaskCall({
                    admin: adminId,
                    task,
                    author: currentAdminName,
                })

                close()
                Notifications.success('Задача была успешно создана')
            } catch {
                Notifications.failure('Не удалось создать задачу')
            }
        },
    })

    useEffect(() => {
        if (!admins) return
        if (!currentAdminId) return

        if (values.type === 'Тех. отдел') {
            close()
            history.push('/trello')
            return
        }

        if (values.type === 'Другому администратору' || values.type === '') {
            setFieldValue('adminId', '')
            return
        }

        if (values.type === 'Себе') {
            setFieldValue('adminId', currentAdminId)
            return
        }

        const admin = admins.find(admin => admin.name === values.type)
        setFieldValue('adminId', admin.uid)
        // eslint-disable-next-line
    }, [admins, currentAdminId, values.type])

    function getText({loading, failed}) {
        if (loading) return <div>Загрузка..</div>
        if (failed) return <div>Не удалось загрузить список админов</div>
        return <div>Нет данных</div>
    }

    return (
        <form onSubmit={handleSubmit}>
            <ModalContent>
                {loaded ? (
                    <AddTaskFormFields
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        admins={admins}
                    />
                ) : (
                    getText({loading, failed})
                )}
            </ModalContent>
            <ModalHR/>
            <ModalControls>
                <ModalButton bsStyle="danger" onClick={close}>
                    Отмена
                </ModalButton>
                <ModalButtonWithLoading
                    bsStyle="success"
                    type="submit"
                    style={{marginLeft: 'auto'}}
                    disabled={!loaded}
                    loading={isSubmitting}
                >
                    Создать
                </ModalButtonWithLoading>
            </ModalControls>
        </form>
    )
}

const AddTaskFormFields = ({values, errors, setFieldValue, admins}) => {
    const currentAdminId = useSelector(sessionSelectors.adminId)

    const typeOptions = types.map(type => ({
        label: type,
        value: type,
    }))

    const adminOptions = admins
        .filter(admin => {
            if (typesMap[admin.name]) return false
            if (admin.uid === currentAdminId) return false
            return true
        })
        .map(admin => ({
            label: admin.name,
            value: admin.uid,
        }))

    return (
        <>
            <Select
                label="Кому отправить задачу"
                name="type"
                value={values.type}
                defaultOption={{
                    label: 'Выберите',
                    value: '',
                }}
                options={typeOptions}
                onChange={value => setFieldValue('type', value)}
                hasError={errors.type}
            />
            {values.type === 'Другому администратору' && (
                <Select
                    label="Администратор"
                    name="adminId"
                    value={values.adminId}
                    defaultOption={{
                        label: 'Выберите',
                        value: '',
                    }}
                    options={adminOptions}
                    onChange={value => setFieldValue('adminId', value)}
                    hasError={errors.adminId}
                />
            )}
            <Textarea
                label="Текст задачи"
                name="task"
                placeholder="Опишите задачу"
                value={values.task}
                onChange={value => setFieldValue('task', value)}
                hasError={errors.task}
            />
        </>
    )
}
