import uuid from 'uuid/v4'
import { loyaltyPurchaseSelectors } from '../../../../loyalty-purchase'
import { getCalculatedItems } from '../../../../helpers/loyalty/getCalculatedItems'
import { loyaltyAPI } from '../../../../api/loyaltyAPI'
import {getDivided} from "./dividePrice";

export const getLoyaltyData = ({ state, items, reservation }) => {
  const calculationQuery = loyaltyPurchaseSelectors.calculationQuery(state)
  const calculatedRowsMap = loyaltyPurchaseSelectors.calculatedRowsMap(state)
  const itemsIds = loyaltyPurchaseSelectors.itemsIds(state)

  const txid = uuid()

  const calculatedItems = getCalculatedItems({
    items,
    txid,
    calculatedRowsMap,
  })

  const calculatedItemsMap = { days: {}, services: {} }

  for (let day of calculatedItems.days) {
    calculatedItemsMap.days[day.id] = day
  }

  for (let service of calculatedItems.services) {
    calculatedItemsMap.services[service.id] = service
  }

  const discountedDays = reservation.reserved_days.map(day => {
    const calculatedDay = calculatedItemsMap.days[day.id]
    if (calculatedDay) return calculatedDay
    if (itemsIds.includes(day.id)) return { ...day, refund_id: txid }
    return day
  })

  const discountedServices = reservation.additional_services.map(service => {
    const calculatedService = calculatedItemsMap.services[service.id]
    if (calculatedService) return calculatedService
    if (itemsIds.includes(service.id)) return { ...service, refund_id: txid }
    return service
  })

  const {reserved_days, additional_services} = getDivided(discountedDays, discountedServices)

  const calculatedReservation = {
    ...reservation,
    reserved_days ,
    additional_services,
  }

  const loyaltyActions = {
    set() {
      return loyaltyAPI.setPurchase({
        txid,
        calculationQuery,
      })
    },
    confirm(ticket) {
      return loyaltyAPI.confirmTicket({
        txid,
        ticket,
      })
    },
    discard(ticket) {
      return loyaltyAPI.discardTicket({
        txid,
        ticket,
      })
    },
  }

  return {
    calculatedItems,
    calculatedReservation,
    loyaltyActions,
  }
}
