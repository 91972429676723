import { connect } from 'react-redux'
import { modalsSelectors, modalsActions } from '../../../../../../Modules/modals'
import { ModalView } from './ModalView'

const mSTP = state => ({
  isOpen: modalsSelectors.isOpen(state, 'fast-booking-button'),
})

const mDTP = dispatch => ({
  close: () => dispatch(modalsActions.close('fast-booking-button')),
})

export const FastBookingModal = connect(mSTP, mDTP)(ModalView)
