import React from "react";
import {SvgBlock, WideBtn} from "./StyledComponents";
import {FaEye, FaEyeSlash, FaListUl, FaRegFileArchive} from "react-icons/fa";
import styled from "styled-components";

const Item = styled.div`
    background-color: ${props => props.bg};
    font-size: ${props => props.extended ? 14 : 12}px;
    width: ${props => props.extended ? 350 : 190}px;
    height: ${props => props.extended ? 350 : 190}px;
    min-height: ${props => props.extended ? 350 : 190}px;
    border-radius: 12px;
    z-index: ${props => props.extended ? 10 : 1};
    cursor: pointer;
    position: relative;

    & > div {
        display: flex;
        flex-direction: column;
        gap: ${props => props.extended ? 12 : 10}px;
        height: 100%;
        padding: ${props => props.extended ? 16 : 10}px;
        border-radius: ${props => props.extended ? 16 : 10}px;
    }

`
const Content = styled.div`
    overflow: auto;
    background-color: white;
    border-radius: 12px;
    padding: 12px;
    flex-grow: 1;

    & > div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: ${props => props.extended ? 'auto' : 6};
    }
`

const IconBlock = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-style: italic;

`

export const Sticker = ({bg, content, index, goToArchive, id, goToList, extended, setExtended}) => {


    const changeStatus = () => {
        goToArchive({id})
        setExtended(null)
    }

    return (
        <Item bg={bg} extended={extended}>
            <div>
                {<IconBlock>
                    {+index + 1}.
                    <div style={{display: 'flex', gap: 5}}>
                        {extended
                            ? <SvgBlock color="#428DFF"><FaEyeSlash color="white" onClick={(e) => {
                                e.stopPropagation()
                                setExtended(null)
                            }}
                            /></SvgBlock>
                            : <SvgBlock color="#428DFF"><FaEye color="white" onClick={(e) => {
                                e.stopPropagation()
                                setExtended(index)
                            }}
                            /></SvgBlock>}
                        <SvgBlock color="#444444"><FaRegFileArchive color="white" onClick={changeStatus}/></SvgBlock>
                    </div>
                </IconBlock>}
                <Content extended={extended}>
                    <div>{content} </div>
                </Content>
                {extended &&
                    <WideBtn onClick={goToList}> <FaListUl/>Все напоминания</WideBtn>
                }
            </div>
        </Item>
    )
}
