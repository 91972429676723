export const getDivided = (days, services) => {
    let reserved_days = days
    let additional_services = services

    const hasZeroDays = days.find(({price}) => price === 0)
    const hasZeroServices = services.find(({price}) => price === 0)
    if (hasZeroDays || hasZeroServices) {

        const total = days.reduce((a, {price}) => a + price, 0) + services.reduce((a, {
            price,
            quantity,
            discount
        }) => a + (price * quantity - discount), 0)


        const fullPrice = services.length ? total / 2 : total
        const num = days.length
        const [div, remainder] = num > 1
            ? [~~(fullPrice * 100 / num), ((fullPrice * 100) % num)]
            : [fullPrice * 100, 0]


        reserved_days = days.map((it, index) => {
            const [addR, addD] = index === 0 ? [remainder, Math.round(remainder)] : [0, 0]
            return {
                ...it,
                price: (div + addR) / 100,
            }
        })

        if (services) {
            const numB = services.length
            const [divB, remainderB] = numB > 1
                ? [~~(fullPrice * 100 / numB), ((fullPrice * 100) % numB)]
                : [fullPrice * 100, 0]
            additional_services = services.map((it, index) => {
                const addR = index === 0 ? remainderB : 0

                const {discount, price, quantity} = it
                const cost = price * quantity - discount

                let lastDiscount = discount
                if (cost > 0) {
                    lastDiscount = lastDiscount + cost
                }
                return {
                    ...it,
                    discount: lastDiscount - ((divB + addR) / 100),
                }
            })
        }
    }
    return {reserved_days, additional_services}
}
