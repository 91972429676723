import React from "react";
import {Loader} from "./Loader";
import styled from 'styled-components'
import history from "../../../../../../../Modules/helpers/history";
import {getUIFormatted} from "../ModalView";
import {Button} from "../../../../../../buttons";

const Item = styled.span`
    display: flex;
    gap: 10px;
    margin: 10px 0;
`

const Link = styled.button`
  padding: 0;
  border: none;
  border-bottom: 1px dashed transparent;
  color: #337ab7;
  background-color: transparent;
  outline: none;
    &:hover {
        border-bottom: 1px #337ab7 solid;
    }
`

export const Reservation = ({data, close}) => {
    const {
        pk,
        start,
        end,
        guests_number,
        guest_name,
        guest_phone,
        guest_mail,
        booking_number,
        note,
        room_names,
        is_group_reservation,
    } = data || {}
    if (data) {
        const goToReservation = () => {
            close()
            history.push(`/reservation?pk=${pk}`)
        }
        const goToWubook = () => {
            close()
            history.push("/wubook")
        }
        return <div>
            <Item>Номер бронирования:<strong>{booking_number}</strong></Item>
            <Item> Даты проживания: <strong>{getUIFormatted(start)} - {getUIFormatted(end)}</strong></Item>
            <Item>Количество гостей: <div>{guests_number}</div></Item>
            <Item>ФИО гостя:
                <div>{guest_name}</div>
            </Item>
            <Item>Телефон: <div>{guest_phone}</div></Item>
            <Item>Email:
                <div>{guest_mail}</div>
            </Item>
            <Item>Комната: <strong>{room_names}</strong>
            </Item>
            <Item>Примечание: <div>{note}</div></Item>
            <Button onClick={goToReservation}>Заселить бронь</Button>
            <br/>
            {is_group_reservation ?
                <div>Бронь является групповой, остальные бронирования можете найти в разделе <Link onClick={goToWubook}>Бронирования</Link>
                </div> : ""}
        </div>
    } else return <Loader/>
}
