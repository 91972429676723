import React from 'react'
import {todayCheckInsActions, todayCheckInsSelectors} from '../../../reducers/today-check-ins'
import {useSelector} from "react-redux";
import {Glyphicon} from "react-bootstrap";
import {useActions} from "../../../../common/hooks/use-actions";
import {CheckIns} from "./CheckIns";
import {settingsSelectors} from "../../../../../Modules/settings";
import {DarkButton} from "../common/DarkButton";

export const TodayCheckIns = () => {
    const wubook = useSelector(todayCheckInsSelectors.todayCheckins)
    const count = useSelector(todayCheckInsSelectors.count)
    const {updateData} = useActions(todayCheckInsActions)
    const isUpdating = useSelector(todayCheckInsSelectors.isUpdating)
    const secondTierEnabled = useSelector(settingsSelectors.isCleaningSecondTierEnabled)

    const updateButton = isUpdating ? (
        <DarkButton disabled>Загрузка..</DarkButton>
    ) : (
        <DarkButton onClick={updateData}>
            <Glyphicon glyph="refresh" style={{marginRight: 8}}/>
            Загрузить новые
        </DarkButton>
    )

    return (
        <CheckIns count={count} wubook={wubook} secondTierEnabled={secondTierEnabled}
                  header='Заезды сегодня' headerControl={updateButton}/>
    )
}
