import moment from "moment/moment";
import {Button} from "../../buttons";
import React from "react";
import styled from 'styled-components'
import {Badge} from "react-bootstrap";
import {ShowMoreComponent} from "../../../Modules/ShowMoreComponent";
import {FaCheck} from "react-icons/fa";

const Td = styled.td`
    vertical-align: middle !important;

    &:first-of-type, &:last-of-type {
        width: 20%;
    }

     &:last-of-type {
             width: ${props => props.short ? '10%' : '20%'}
     }

`


const Title = styled.div`
    font-weight: 700;
    font-size: 16px;
`


const Stack = styled.div`
    display: flex;
    gap: 5px;
`


export const NewsTable = ({list, setRead, showControls, short}) => {
    const read = (id) => {
        setRead({id})
    }

    return (
        <tbody>
        {list && list.map((it) => {
            return (<tr key={it.id + '_news'} style={{paddingBottom: 30}}>
                <Td>{moment(it.publication_at).format('DD.MM.YYYY HH:mm')}</Td>
                <Td><NewsItem item={it} short={short}/></Td>
                {showControls && !it.mark
                    ? <Td short> {short
                        ? <Button onClick={() => read(it.id)}><FaCheck/></Button>
                        : <Button onClick={() => read(it.id)}>Пометить прочитанным</Button>}
                    </Td>
                    : <Td/>}
            </tr>)
        })}
        </tbody>
    )
}

const NewsItem = ({item, short}) => {

    return (<div>
        <Title>{item.title}</Title>
        <Stack>
            {item.categories.map((it) => (
                <Badge style={{backgroundColor: it.color}} key={it.id + '_' + item.id}>{it.name}</Badge>
            ))}
        </Stack>
        <ShowMoreComponent description={item.description} maxLines={short ? 5 : 10} maxLength={short ? 150 : 100}/>
    </div>)
}
