import CheckPrintController from '../CheckPrintController'
import {
  confirmTransactionCall,
  cancelTransactionCall,
  getTransactionsCall,
  returnPaymentByPaymentCardCall,
  getTransactionsWithoutAuthCall,
} from '../api'
import { inputAmount } from './inputAmount'
import moment from 'moment'
import printSlip from './printSlip'
import { Notifications } from '../Notifications'
import { callModal, modalTemplates } from '../../Components/dynamic-modal'
import store from '../../store'
import { sessionSelectors } from '../session'
import { createTransactionCall, createTransactionWithoutAuthCall } from '../api/ccAPI/requests'

class TransactionsAPI {
  get lcode() {
    const state = store.getState()

    return sessionSelectors.lcode(state)
  }

  get = async date => {
    return getTransactionsCall({
      lcode: this.lcode,
      date,
    })
  }

  getWithoutAuth = async date => {
    return getTransactionsWithoutAuthCall({
      lcode: this.lcode,
      date,
    })
  }

  create = async () => {
    let total = await inputAmount()

    // "Cancel" was fired on input-amount modal
    if (!total) {
      callModal(
        modalTemplates.alert({
          text: 'Операция была отменена',
        })
      )
      return
    }

    try {
      const kkmResponse = await CheckPrintController.lockPaymentCardSumm({
        amount: total,
      })

      if (kkmResponse.Status !== 0) {
        callModal(
          modalTemplates.alert({
            title: 'Ошибка KKM',
            text: kkmResponse.Error,
          })
        )
        return
      }

      const rrn = kkmResponse.RRNCode
      const authCode = kkmResponse.AuthorizationCode
      const date = moment().format('YYYY-MM-DD HH:mm')

      // Рубли в копейки
      const newTotal = +total * 100

      await printSlip(kkmResponse.Slip)

      await createTransactionCall({
        rrn,
        authCode,
        lcode: this.lcode,
        date,
        total: newTotal,
      })

      Notifications.success('Транзакция успешно создана')

      return true
    } catch {
      callModal(
        modalTemplates.alert({
          title: 'Неизвестная ошибка',
          text: 'Операция была отменена',
        })
      )
    }
  }

  createFromPayment = async ({ rrn, authCode, total, inn }) => {
    const date = moment().format('YYYY-MM-DD HH:mm')

    // Рубли в копейки
    const newTotal = +total * 100

    try {
      await createTransactionWithoutAuthCall({
        rrn,
        authCode,
        lcode: this.lcode,
        date,
        total: newTotal,
        inn,
      })

      return true
    } catch {
      callModal(
        modalTemplates.alert({
          title: 'Ошибка',
          text: 'Не удалось сохранить транзакцию',
        })
      )
    }
  }

  confirm = async ({ rrn, authCode, total }, additionalProps) => {
    const date = moment().format('YYYY-MM-DD HH:mm')

    const { action } = await callModal(
      modalTemplates.confirm({
        text: 'Вы уверены, что хотите списать заблокированную сумму?',
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }

    try {
      const kkmResponse = await CheckPrintController.writeOffPaymentCardSumm({
        rrn,
        authCode,
        amount: total,
      })

      if (kkmResponse.Status !== 0) {
        callModal(
          modalTemplates.alert({
            title: 'Ошибка KKM',
            text: kkmResponse.Error,
          })
        )
        return
      }

      await printSlip(kkmResponse.Slip)

      await confirmTransactionCall({
        rrn,
      })

      const newTotal = total * 100

      const name = 'Услуги проживания'

      await CheckPrintController.printCheckWithoutProcessing({
        lines: [
          {
            Register: {
              Name: name,
              Quantity: 1,
              Price: total,
              Amount: total,
              Department: 0,
              Tax: 0,
              SignMethodCalculation: 4,
              SignCalculationObject: 4,
              MeasurementUnit: 'шт',
            },
          },
        ],
        linesStringCash: '',
        linesStringCard: `${name}~1~${total}`,
        values: {
          payedByCard: true,
          cardSumm: total,
        },
        additionalProps: {
          ...additionalProps,
          isRefund: false,
        },
      })

      await createTransactionWithoutAuthCall({
        rrn,
        authCode,
        lcode: this.lcode,
        date,
        total: newTotal,
      })

      return true
    } catch {
      callModal(
        modalTemplates.alert({
          title: 'Неизвестная ошибка',
          text: 'Операция была отменена',
        })
      )
    }
  }

  cancel = async ({ rrn, authCode, total }) => {
    const { action } = await callModal(
      modalTemplates.confirm({
        text: 'Вы уверены, что хотите разблокировать сумму на карте?',
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }

    try {
      const kkmResponse = await CheckPrintController.unlockPaymentCardSumm({
        rrn,
        authCode,
        amount: total,
      })

      if (kkmResponse.Status !== 0) {
        callModal(
          modalTemplates.alert({
            title: 'Ошибка KKM',
            text: kkmResponse.Error,
          })
        )
        return
      }

      await printSlip(kkmResponse.Slip)

      await cancelTransactionCall({
        rrn,
      })

      return true
    } catch {
      callModal(
        modalTemplates.alert({
          title: 'Неизвестная ошибка',
          text: 'Операция была отменена',
        })
      )
    }
  }

  return = async ({ rrn, authCode, total }) => {
    const { action } = await callModal(
      modalTemplates.confirm({
        text: 'Вы уверены, что хотите отменить оплату картой?',
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }

    try {
      const kkmResponse = await CheckPrintController.returnPaymentByPaymentCard(
        {
          rrn,
          authCode,
          amount: total,
        }
      )

      if (kkmResponse.Status !== 0) {
        callModal(
          modalTemplates.alert({
            title: 'Ошибка KKM',
            text: kkmResponse.Error,
          })
        )
        return
      }

      await printSlip(kkmResponse.Slip)

      await returnPaymentByPaymentCardCall({
        rrn,
      })

      return true
    } catch {
      callModal(
        modalTemplates.alert({
          title: 'Неизвестная ошибка',
          text: 'Операция была отменена',
        })
      )
    }
  }
}

export default new TransactionsAPI()
