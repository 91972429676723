import React from "react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import styled from "styled-components";


const Arrow = styled.div`
    display: block;
    background: #B3BACA;
    border-radius: 50%;
    top: 100%;
    transform: none;
    margin: 10px 0;
    height: 24px;
    width: 24px;
    padding: 2px;
    right: unset;
    left: ${props => props.color};
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        color: white;
    }
`

export const BtnBlock = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    gap: 5px;
`

export const SampleNextArrow = ({onClick}) => {
    return (
        <Arrow
            left={30}
            onClick={onClick}>
            <FaAngleRight/>
        </Arrow>
    );
}

export const SamplePrevArrow = ({onClick}) => {
    return (
        <Arrow
            left={0}
            onClick={onClick}>
            <FaAngleLeft/>
        </Arrow>
    );
}
