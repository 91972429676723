import React, {Component} from 'react'
import styled from 'styled-components'
import {Button, Form} from 'react-bootstrap'
import TextField from '../common/Textfield'
import {Redirect, withRouter} from 'react-router-dom';
import {callPrompt} from '../dynamic-modal'
import {Notifications} from '../../Modules/Notifications'

const Header = styled.h3`
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 700;
`

const LoginBlock = styled.div`
    display: flex;
    height: 100%;
    > div{
        margin: auto;
    }
`

const HotelBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Item = styled.div`
    border: 1px solid #46b8da;
    padding: 6px 12px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
`

const LoginContent = styled.div`
    margin: auto;
    width: 250px;
`
const HotelContent = styled.div`
    margin: auto;
    width: 900px;
`
const ShiftChanging = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    width: 900px;
    padding-bottom: 25px;
    line-height: 1;
`


const LoginHead = styled.h2`
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 20px 25px;
    background-color: #8f7409;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`

const LoginBody = styled.div`
    padding: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px #8f7409 solid;
`

const ErrorBlock = styled.div`
    text-align: center;
`

const LoginError = styled.div`
    margin: 20px auto 0;
    color: crimson;
`
const selectedColor = '#5bc0de'

class Login extends Component {
    state = {
        admins: [],
        isAdminsLoading: false,
        isAdminsFailed: false,
        login: '',
        password: '',
        selectedLcode: null,
        isShift: false
    }

    componentDidUpdate(prevProps) {
        if(this.props.history.location?.state?.shift !== this.state.isShift) {
            const isShift = this.props.history.location?.state?.shift
            this.setState({isShift})
        }
    }

    handleLoginChange = login => this.setState({login})
    handlePasswordChange = password => this.setState({password})
    handleLcodeSubmit = async () => {

        const lcode = this.props.hotelsMappings.lcodeByPk[this.state.selectedLcode.key]
        await this.props.sessionActions.setCookieLcode(lcode)
        await this.props.loadData()

        this.props.getOpenShift(lcode.lcode, this.state.isShift)
    }

    handleSubmit = async event => {
        event.preventDefault()
        const {login, password} = this.state
        if (!login || !password) {
            this.props.sessionActions.setSessionError('Заполните поля')
            return
        }
        await this.props.sessionActions.loginAdmin(login, password)
        if (this.props.isAuthorized) {
            await this.props.hotelsActions.load()
        }
    }

    callRestoreModal = async () => {
        const res = await callPrompt({
            title: `Введите email для восстановления пароля`,
        })
        if (res) {
            const found = await this.props.sessionActions.resetPassword(res)
            if (found) {
                Notifications.success('Успех')
            } else {
                Notifications.failure('Email не найден')
            }
        }
    }

    render() {
        if (this.props.isAuthorized && this.props.lcode !== null) {
            return <Redirect to="/"/>
        }

        const {
            login,
            password,
        } = this.state

        if (this.props.isAuthorized && this.props.lcodes) {
            return (
                <LoginBlock>
                    <HotelContent>
                        <Header>Выберите отель</Header>
                        <HotelBlock>
                            {this.props.lcodes.map((lcode) => (
                                <Item
                                    key={lcode.value}
                                    style={{
                                        flex: '25%',
                                        margin: '5px',
                                        backgroundColor: this.state.selectedLcode && this.state.selectedLcode.key === lcode.key ? selectedColor : 'white'
                                    }}
                                    onClick={() => this.setState({selectedLcode: lcode})}>
                                    {lcode.label}
                                </Item>
                            ))}
                        </HotelBlock>

                        <Button
                            type="submit"
                            bsStyle="success"
                            disabled={this.state.selectedLcode === null}
                            block
                            onClick={this.handleLcodeSubmit}
                        >
                            Перейти к "{this.state.selectedLcode ? this.state.selectedLcode.label : '...'}"
                        </Button>
                    </HotelContent>
                </LoginBlock>
            )
        }
        return (
            <LoginBlock>
                <div>
                    {this.state.isShift && <ShiftChanging>Войдите в систему под учетной запись администратора, сдающего
                        смену. </ShiftChanging>}
                    <LoginContent>
                        <LoginHead>Представьтесь</LoginHead>
                        <LoginBody>
                            <Form onSubmit={this.handleSubmit}>
                                <TextField
                                    name="Email"
                                    type="text"
                                    value={login}
                                    onChange={this.handleLoginChange}
                                    style={{marginBottom: 15}}
                                />
                                <TextField
                                    name="Пароль"
                                    type="password"
                                    value={password}
                                    onChange={this.handlePasswordChange}
                                    style={{marginBottom: 20}}
                                />
                                <Button type="submit" bsStyle="success" style={{width: '100%'}}>
                                    Войти
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    style={{width: '100%', marginTop: '10px'}}
                                    onClick={this.callRestoreModal}
                                >
                                    Я забыл пароль
                                </Button>
                                <ErrorBlock>
                                    {this.props.error && (
                                        <LoginError>{this.props.error}</LoginError>
                                    )}
                                </ErrorBlock>
                            </Form>
                        </LoginBody>
                    </LoginContent>
                </div>
            </LoginBlock>
        )
    }
}

export default  withRouter(Login)
