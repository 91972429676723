import React from 'react'
import styled from "styled-components";

const Block = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: #ffff;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 12px;
    padding-bottom: 8px;
`

const BlockLarge = styled(Block)`
    font-size: 20px;
    padding: 0;
`

export const NavbarLogo = () => <BlockLarge>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M22.0299 29.6439C22.0299 30.1561 21.8374 30.6683 21.8374 31.078C21.8374 31.5903 22.0299 32 22.5114 32H24.6298C24.9187 32 25.1112 31.8975 25.2076 31.6927C25.4964 30.6683 25.015 30.0536 25.015 28.9268C25.015 25.9559 26.1705 16.941 26.6519 13.5603C26.7482 12.4335 26.9408 10.1798 27.5186 9.3602C27.8075 8.95049 28 8.6431 28 8.43831C28 8.23339 27.8075 8.13092 27.037 8.13092H25.015C23.9558 8.13092 24.3409 8.95049 24.5335 8.95049C25.3039 9.46266 25.015 10.1798 25.015 11.9213C25.015 13.6628 24.1484 26.1607 23.8595 26.0584C23.2817 21.5509 22.8003 16.8386 22.3188 12.2286C22.0299 8.54064 22.5114 8.13092 21.7411 8.13092H19.9115C18.082 8.02846 19.4301 8.74556 19.4301 9.56512C19.3338 13.0482 17.5043 25.5461 17.5043 25.9559C17.2154 23.9071 16.6377 9.77005 16.4451 8.43831C16.3488 7.926 15.8673 8.13092 15.4822 8.02846C12.786 7.926 12.5934 8.02846 13.4601 9.56512C13.8452 10.2822 13.8452 11.5115 14.0378 13.1506C14.5193 17.8629 14.9044 22.5754 15.2896 27.1852C15.6747 30.8732 14.423 31.7951 15.771 32H18.4672C19.0449 32 19.0449 31.5903 19.0449 31.2829C19.0449 30.9756 18.7561 30.1561 18.8524 28.9268C18.9486 27.1852 19.3338 24.829 19.6227 22.4729C20.1041 18.1703 20.6819 14.0726 20.7782 14.0726C20.8745 14.175 21.1633 17.9654 21.5485 21.8583C21.5485 25.2388 21.9337 28.6194 22.0299 29.6439Z"
              fill="#B79F47"/>
    </svg>
    WF PMS
</BlockLarge>

export const AdminInfo = ({userName}) => <Block>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.4853 3.51473C18.2188 1.24823 15.2053 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51473C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51473ZM12 22.5938C9.14794 22.5938 6.55584 21.4605 4.64944 19.621C5.68181 16.4666 8.64783 14.2969 12 14.2969C15.3522 14.2969 18.3182 16.4666 19.3506 19.621C17.4442 21.4605 14.8521 22.5938 12 22.5938ZM8.48438 9.375C8.48438 7.43648 10.0615 5.85938 12 5.85938C13.9385 5.85938 15.5156 7.43648 15.5156 9.375C15.5156 11.3135 13.9385 12.8906 12 12.8906C10.0615 12.8906 8.48438 11.3135 8.48438 9.375ZM20.4066 18.4385C19.372 16.0181 17.3407 14.179 14.8858 13.3596C16.1186 12.4642 16.9219 11.0118 16.9219 9.375C16.9219 6.66108 14.7139 4.45312 12 4.45312C9.28608 4.45312 7.07812 6.66108 7.07812 9.375C7.07812 11.0118 7.88142 12.4642 9.11428 13.3596C6.65925 14.179 4.62797 16.0181 3.59344 18.4385C2.22253 16.6528 1.40625 14.4201 1.40625 12C1.40625 6.15858 6.15858 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15858 22.5938 12C22.5938 14.4201 21.7775 16.6528 20.4066 18.4385Z"
            fill="white"/>
    </svg>
    {userName}
</Block>

export const HotelInfo = ({hotel}) => <Block>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 22.5H9C8.5875 22.5 8.25 22.1625 8.25 21.75V17.25C8.25 16.0125 9.2625 15 10.5 15H13.5C14.7375 15 15.75 16.0125 15.75 17.25V21.75C15.75 22.1625 15.4125 22.5 15 22.5ZM9.75 21H14.25V17.25C14.25 16.8375 13.9125 16.5 13.5 16.5H10.5C10.0875 16.5 9.75 16.8375 9.75 17.25V21Z"
            fill="white"/>
        <path
            d="M19.5 22.5H4.5C4.0875 22.5 3.75 22.1625 3.75 21.75V3.5C3.75 1.8425 5.0925 0.5 6.75 0.5H17.25C18.9075 0.5 20.25 1.8425 20.25 3.5V21.75C20.25 22.1625 19.9125 22.5 19.5 22.5ZM5.25 21H18.75V3.5C18.75 2.675 18.075 2 17.25 2H6.75C5.925 2 5.25 2.675 5.25 3.5V21Z"
            fill="white"/>
        <path
            d="M21 22.5H3C2.5875 22.5 2.25 22.1625 2.25 21.75C2.25 21.3375 2.5875 21 3 21H21C21.4125 21 21.75 21.3375 21.75 21.75C21.75 22.1625 21.4125 22.5 21 22.5Z"
            fill="white"/>
        <path
            d="M9.75 9.75H7.5C7.0875 9.75 6.75 9.4125 6.75 9C6.75 8.5875 7.0875 8.25 7.5 8.25H9.75C10.1625 8.25 10.5 8.5875 10.5 9C10.5 9.4125 10.1625 9.75 9.75 9.75Z"
            fill="white"/>
        <path
            d="M9.75 6.5H7.5C7.0875 6.5 6.75 6.1625 6.75 5.75C6.75 5.3375 7.0875 5 7.5 5H9.75C10.1625 5 10.5 5.3375 10.5 5.75C10.5 6.1625 10.1625 6.5 9.75 6.5Z"
            fill="white"/>
        <path
            d="M9.75 12.75H7.5C7.0875 12.75 6.75 12.4125 6.75 12C6.75 11.5875 7.0875 11.25 7.5 11.25H9.75C10.1625 11.25 10.5 11.5875 10.5 12C10.5 12.4125 10.1625 12.75 9.75 12.75Z"
            fill="white"/>
        <path
            d="M16.5 9.75H14.25C13.8375 9.75 13.5 9.4125 13.5 9C13.5 8.5875 13.8375 8.25 14.25 8.25H16.5C16.9125 8.25 17.25 8.5875 17.25 9C17.25 9.4125 16.9125 9.75 16.5 9.75Z"
            fill="white"/>
        <path
            d="M16.5 6.5H14.25C13.8375 6.5 13.5 6.1625 13.5 5.75C13.5 5.3375 13.8375 5 14.25 5H16.5C16.9125 5 17.25 5.3375 17.25 5.75C17.25 6.1625 16.9125 6.5 16.5 6.5Z"
            fill="white"/>
        <path
            d="M16.5 12.75H14.25C13.8375 12.75 13.5 12.4125 13.5 12C13.5 11.5875 13.8375 11.25 14.25 11.25H16.5C16.9125 11.25 17.25 11.5875 17.25 12C17.25 12.4125 16.9125 12.75 16.5 12.75Z"
            fill="white"/>
    </svg>
    {hotel}
</Block>

export const LogOut = ({onClick}) => <Block onClick={onClick} style={{cursor: 'pointer'}}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 4H5V18C5 19.1046 5.89543 20 7 20H15" stroke="white" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M16 15L19 12M19 12L16 9M19 12H9" stroke="white" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>

</Block>
