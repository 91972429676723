import React from "react";
import styled from "styled-components";

export const InlineItem = styled.div`
    display: flex;

    ul {
        padding-right: 20px;
        padding-top: 5px;
    }

    svg {
        margin-right: 10px;
    }
`

export const AboutCancel = styled.div`
  font-size: 14px;
  margin-top: 0;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-style: dashed;
  width: fit-content;
  cursor: pointer;

  svg {
    vertical-align: middle;
    margin-left: 5px;
  }
`


export const QuestionIcon = () => (
    <svg
        fill="none"
        height="14"
        viewBox="0 0 14 14"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="7" cy="7" fill="#428DFF" r="7" />
        <path
            d="M7 11.0332C7.37754 11.0332 7.68359 10.7271 7.68359 10.3496C7.68359 9.97207 7.37754 9.66602 7 9.66602C6.62246 9.66602 6.31641 9.97207 6.31641 10.3496C6.31641 10.7271 6.62246 11.0332 7 11.0332Z"
            fill="white"
        />
        <path
            d="M7 3.51367C5.79381 3.51367 4.8125 4.49498 4.8125 5.70117C4.8125 6.00321 5.05734 6.24805 5.35938 6.24805C5.66141 6.24805 5.90625 6.00321 5.90625 5.70117C5.90625 5.09808 6.39691 4.60742 7 4.60742C7.60309 4.60742 8.09375 5.09808 8.09375 5.70117C8.09375 6.30427 7.60309 6.79492 7 6.79492C6.69796 6.79492 6.45312 7.03976 6.45312 7.3418V8.70898C6.45312 9.01102 6.69796 9.25586 7 9.25586C7.30204 9.25586 7.54688 9.01102 7.54688 8.70898V7.81946C8.4893 7.57602 9.1875 6.71861 9.1875 5.70117C9.1875 4.49498 8.20619 3.51367 7 3.51367Z"
            fill="white"
        />
    </svg>
)
