import React from "react";
import styled from 'styled-components'
import {Button} from "../../../../../../buttons";
import {rub} from "../../../../../../../Modules/formatters/currency";
import {Loader} from "./Loader";
import {getFormatted} from "../ModalView";

const RoomNum = styled.div`
    display: flex;
    border: 1px solid #d8d8d8;
    border-radius: 4px;

    div {
        padding: 0 5px;
    }

    button {
        width: 20px;
        height: 20px;
        background-color: #12ab45;
        border: none;
        color: white;
        padding: 0;
        margin: 0;
        border-radius: 0 3px 3px 0;

        :first-child {
            border-radius: 3px 0 0 3px;
        }
    }
`
const Block = styled.div`
    overflow: auto;
    height: -webkit-fill-available;
`
const Capacity = styled.div`
    color: #d61b3e;
    font-weight: 700;
    text-align: right;
`
const Error = styled.div`
    font-size: 12px;
    color: #d61b3e;
`
const Flex = styled.div`
    display: flex;
    gap: 15px;
    padding: 15px 25px;
    align-items: baseline;

    &:not(:last-child) {
        border-bottom: 1px solid #d8d8d8;
    }
`

export const RoomsData = ({values: {selectedRooms, end, capacity, guests}, rooms, errors, setFieldValue}) => {
    const busy = Object.values(selectedRooms).reduce((a, {num, generalRoomId}) => {
        if (a[generalRoomId])
            a[generalRoomId] = a[generalRoomId] + num
        else
            a[generalRoomId] = num
        return a
    }, {})
    if (rooms)
        return <Block>
            <Capacity>Вместимость: {capacity} мест/ {guests} гостя(ей) </Capacity>
            {Object.entries(rooms).map(([generalRoomId, {name: generalName, avail, rooms: subRooms}]) => {
                if (subRooms.length) {
                    return <div key={generalRoomId}><strong>{generalName}:</strong>{
                        subRooms.map(it => Object.entries(it).map(([room_id, {
                            name,
                            number_of_guests,
                            prices
                        }]) => {
                            delete prices[getFormatted(end)]
                            const price = Object.values(prices).reduce((a, b) => a + b, 0)

                            return <Flex key={room_id}>
                                <div><strong>{name}</strong>
                                    <div>Вместимость: {number_of_guests}</div>
                                </div>
                                <div> Доступно: {avail - (busy[generalRoomId] | 0)}</div>

                                <QuantitySelector
                                    selected={selectedRooms?.[room_id]?.num | 0}
                                    max={avail - (busy[generalRoomId] | 0)}
                                    setSelected={(val) => {
                                        const it = {...selectedRooms}
                                        it[room_id] = {
                                            price: prices,
                                            num: val,
                                            room_id,
                                            generalRoomId,
                                            name,
                                            number_of_guests
                                        }
                                        const capacity = Object.values(it).reduce((a, {
                                            number_of_guests,
                                            num
                                        }) => a + num * number_of_guests, 0)
                                        setFieldValue('selectedRooms', it)
                                        setFieldValue('capacity', capacity)
                                    }}/>
                                <strong>{rub(price * selectedRooms?.[room_id]?.num | 0)}</strong>
                            </Flex>
                        }))
                    }</div>
                }
            })}
            <Error>{errors.selectedRooms}</Error>
        </Block>

    return <Loader/>
}


export const QuantitySelector = ({selected, setSelected, max}) => {
    return <RoomNum>
        <Button disabled={selected === 0} onClick={() => setSelected(selected - 1)}>-</Button>
        <div>{selected}</div>
        <Button disabled={max === 0} onClick={() => setSelected(selected + 1)}>+</Button>
    </RoomNum>
}
