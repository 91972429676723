import React from "react";
import styled from 'styled-components'
import moment from "moment/moment";
import {Button} from "../../buttons";
import {FaPencilAlt, FaRegFileArchive, FaWalking} from "react-icons/fa";
import {archive, relevant} from "../reducer/selectors";

const Content = styled.div`
    background: #fff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0 5px 2px -3px rgba(0, 0, 0, .08);
    border-radius: 2px;
    margin: 15px;


    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 1rem;
        width: fit-content;
        cursor: pointer;
        white-space: normal;
    }

    span {
        background-color: #efefef;
        padding: 2px 6px;
        margin-right: 10px;
    }

    div {
        width: 100%;
        text-align: right;
        padding-top: 10px;

        button {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
`

export const Item = ({item: {id, content, category, admin, created_at}, changeStatus, editSticker}) => {
    return (
        <Content>
            <p>{content}</p>
            <span>{moment(created_at).format("DD MMMM")}</span> {admin.short_name}
            <div>
                <Button onClick={() => editSticker({id, content, category})}><FaPencilAlt/></Button>
                {category === relevant &&
                    <Button type="warning" onClick={() => changeStatus(id)}><FaRegFileArchive/></Button>}
                {category === archive && <Button type="primary" onClick={() => changeStatus(id)}><FaWalking/></Button>}
            </div>
        </Content>
    )
}
