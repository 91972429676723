import styled from "styled-components";

export const Link = styled.button`
    padding: 0;
    border: none;
    border-bottom: 1px dashed transparent;
    color: #438EFF;
    background-color: transparent;
    outline: none;
`
