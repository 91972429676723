import React from 'react'
import {FastBookingButton} from "./button/Button";
import {FastBookingModal} from "./modal/Modal";
import {Template} from "../common/Template";

export const FastBooking = () =>
    <Template>
        <FastBookingButton/>
        <FastBookingModal/>
    </Template>
