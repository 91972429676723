import React from "react";
import styled from "styled-components";
import {Warning} from "./Warning";


const Block = styled.div`
    padding: 30px 30px 0;

    h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
        margin-top: 0;
    }
`
const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Template = ({header, warning, headerControl, children}) => (
    <Block>
        {header && <Flex><h2>{header}</h2>{headerControl}</Flex>}
        {warning && <Warning text={warning}/>}
        {children}
    </Block>
)
