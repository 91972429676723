import React from 'react'
import styled from 'styled-components'
import {CalendarSvg} from "./CalendarSvg";

const StyledButton = styled.button`
    width: 100%;
    padding: 6px 12px;
    border: none;
    outline: none;
    background-color: #428DFF;
    border-radius: 4px;
    color: #ffffff;
    height: 42px;

    transition: background-color 0.3s;

    &:hover {
        background-color: #0d6efd;
    }

    svg {
        vertical-align: middle;
        margin-right: 5px;
    }
`

export const ButtonView = ({open}) => {
    return (
        <StyledButton onClick={open}>
            <CalendarSvg/>
            Быстрое бронирование
        </StyledButton>
    )
}
